export enum PeriodType {
  TRIAL = 'trialPeriod',
  STANDARD_YEAR1 = 'standardYear1',
  STANDARD_YEAR2 = 'standardYear2'
}

const toCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

export const getCancelOption = async (canceledOption, allOptions) => {
  const cancelledDate = new Date(canceledOption.createdAt)

  const filteredData = allOptions.filter((item) => {
    const validFromDate = new Date(item.details.validFrom)
    const validUntilDate = new Date(item.details.validUntil)

    return (
      item.details.returnDevice === true &&
      cancelledDate > validFromDate &&
      cancelledDate < validUntilDate
    )
  })

  return filteredData[0]
}

export const getCancelledCost = async (cancelledOption) => {
  if (cancelledOption?.name === PeriodType.TRIAL) {
    return toCurrency(cancelledOption?.outcomes?.[0]?.outcomes[0]?.value?.cost)
  } else {
    return toCurrency(cancelledOption?.outcomes?.[0]?.value?.cost)
  }
}

import tokens from '@veneer/tokens'
import styled from 'styled-components'
import IconCalendarMonth from '@veneer/core/dist/esm/scripts/icons/icon_calendar_month'
import { StaticHeightCard } from '../styles'

const { gray6, gray9, hpBlue7 } = tokens.color
const { size0, size1, size2, lineHeight1, lineHeight2, lineHeight4 } =
  tokens.typography

const {
  cornerRadius1,
  size5: layoutSize5,
  size2: layoutSize2,
  size6: layoutSize6,
  size1: layoutSize1,
  size4: layoutSize4
} = tokens.layout

export const StyledIconCalendarMonth = styled(IconCalendarMonth)`
  && {
    color: ${gray6};
  }
`

export const StyledCard = styled(StaticHeightCard)`
  min-height: 262px !important;
  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & > div > section > header {
    margin-bottom: ${layoutSize5};
  }
  @media (max-width: 1008px) {
    & > div > section > header > h2 {
      font-size: ${size2};
      line-height: ${layoutSize5};
    }
  }
`
export const ProgressWrapper = styled.div`
  margin-top: ${layoutSize5};
  margin-bottom: ${layoutSize5};

  > :not(:last-child) {
    margin-bottom: ${layoutSize6};
  }
`

export const AdditionalProgressText = styled.p`
  margin-top: ${layoutSize2};
  font-size: ${size0};
  line-height: ${lineHeight1};
  text-align: left;
`

export const PlanAndTrialProgressMessage = styled.p`
  margin-top: ${layoutSize4};
  color: #1c7a17;
  font-size: ${size2};
  line-height: ${lineHeight2};
  text-align: left;
`

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`
export const TitleContent = styled.p`
  color: ${gray9};
  font-size: ${layoutSize5};
  line-height: ${lineHeight4};
`
export const SyncBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
`
export const MessageBlock = styled.div`
  text-align: right;
  white-space: pre-line;
  color: ${gray6};
  font-size: ${size0};
  line-height: ${lineHeight1};

  @media (max-width: 600px) {
    text-align: left;
  }
`

export const LoaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${hpBlue7};
`

export const PeriodBlock = styled.p`
  display: flex;
  padding: 4px 0;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  color: ${gray6};
  font-size: ${size0};
  line-height: ${lineHeight1};
  margin-top: 1rem;
  gap: ${layoutSize1};
`
export const PagesBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${layoutSize2};
  border-radius: ${cornerRadius1};
  background-color: #0076ad0d;

  & > p {
    color: ${gray9};
    font-size: ${size1};
    line-height: ${lineHeight2};
  }
`

import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import { StaticHeightCard } from '../styles'

const { gray12, highContrastGray } = tokens.color

const { size4: layoutSize4 } = tokens.layout
export const ShipppingDetailsStaticHeightCard = styled(StaticHeightCard)`
  & section > header {
    margin-bottom: ${layoutSize4} !important;

    > h2 {
      color: ${gray12};
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      @media (min-width: 1008px) {
        color: ${(props) => (props.$disabled ? highContrastGray : gray12)};
      }
    }
  }
`

import React, { useEffect, useState } from 'react'
import { CardLink, CardLinkWrapper } from '../styles'
import {
  navigationLinks,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  PaymentMethodStaticHeightCard,
  StyledImage,
  StyledSubTitle,
  StyledTitle,
  ChevronRightIcon
} from './PaymentMethodCard.styles'
import {
  StyledIconCreditCard,
  ExpiredCard,
  ExpiredTitle,
  AboutExpireTitle,
  StyledAboutExpireSubTitle,
  CardErrorContent,
  CardErrorDescription
} from './PaymentMethodCard.styles'
import {
  isDeliquency,
  isDeliquencyStateOverdue,
  DeliquencyNotificationTypes
} from '../../utils/common-util'
import getHpOnePaymentCardInfo from '../../helpers/getHpAllInPaymentCardInfo'
import IconWarning from '@veneer/core/dist/esm/scripts/icons/icon_warning'
import { CommonProps } from 'src/utils/common-util'

interface PrinterProps {
  PaymentData: any
  loading: any
  error: any
  called: any
  printer: any
  deliquencyNotification: any
  returnDetails: string
  commonProps: CommonProps
}

const PaymentMethodCard: React.FunctionComponent<PrinterProps> = ({
  deliquencyNotification,
  PaymentData,
  loading,
  error,
  called,
  printer,
  returnDetails,
  commonProps
}) => {
  const { authProvider, t } = commonProps
  const { shippingInformation } = navigationLinks
  const [isCancelState, setCancelState] = useState<boolean>(false)
  const disableCardLink = printer?.state === SubscriptionStateEnum.CANCELED
  const suspendedORExpired =
    isDeliquency(deliquencyNotification) ||
    deliquencyNotification === DeliquencyNotificationTypes.EXPIRED
  const overDueORAboutToExpire = isDeliquencyStateOverdue(
    deliquencyNotification
  )
  const Expired = deliquencyNotification === DeliquencyNotificationTypes.EXPIRED
  const AboutToExpire =
    deliquencyNotification === DeliquencyNotificationTypes.ABOUT_TO_EXPIRED

  const paymentMethodDetails = PaymentData?.payment_method_details
  const creditCardTypeInfo = getHpOnePaymentCardInfo(
    paymentMethodDetails?.card_type
  )

  useEffect(() => {
    const fetchPayment = () => {
      return PaymentData
    }
    if (returnDetails === 'completeSuccessful') {
      setCancelState(true)
    } else {
      fetchPayment()
    }
  }, [authProvider, called, PaymentData, printer?.state, returnDetails])

  const isPaymentData = !loading && !!PaymentData
  const isLoading = loading
  const isOnErrorState =
    (!loading && (!!error || !isPaymentData)) || isCancelState

  const txtStates = {
    error: [
      <>
        <div>
          <CardErrorContent>
            {t(
              'dashboard.paas.payment-method.error.title.paragraph-1',
              'Something went wrong.'
            )}
          </CardErrorContent>

          <CardErrorContent>
            {t(
              'dashboard.paas.payment-method.error.title.paragraph-2',
              'Your payment method information did not load.'
            )}
          </CardErrorContent>
        </div>

        <CardErrorDescription>
          {t(
            'dashboard.paas.payment-method.error.description',
            'Please refresh the page.'
          )}
        </CardErrorDescription>
      </>
    ],

    isCancelState: [
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.payment-method.inactive',
            'Your payment method is no longer available'
          )}
        </CardErrorContent>
      </>
    ]
  }
  const getErrorTexts = () => {
    if (isCancelState) {
      return txtStates.isCancelState
    } else {
      return txtStates.error
    }
  }
  return (
    <PaymentMethodStaticHeightCard
      $suspendedORExpired={suspendedORExpired}
      $overDueORAboutToExpire={overDueORAboutToExpire}
      icon={<StyledIconCreditCard size={32} />}
      title={t('dashboard.paas.payment-method.title', 'Payment method')}
      footer={
        <CardLink
          commonProps={commonProps}
          disabled={disableCardLink}
          path={shippingInformation}
          text={
            <CardLinkWrapper id="manage-payments-button">
              {t(
                'dashboard.paas.payment-method.manage-payments',
                'Manage payments'
              )}

              <ChevronRightIcon disabled={disableCardLink && !isCancelState} />
            </CardLinkWrapper>
          }
        />
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={isOnErrorState}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<StyledIconCreditCard size={32} />}
            disabled={disableCardLink && !isCancelState}
            texts={getErrorTexts()}
          />
        }
      >
        <div>
          <StyledImage
            src={creditCardTypeInfo?.cardImage}
            alt={creditCardTypeInfo?.cardName}
          />
          <div>
            <StyledTitle>{paymentMethodDetails?.name_on_card}</StyledTitle>
            <StyledSubTitle>
              XXXX-XXXX-XXXX-{paymentMethodDetails?.last_four}
            </StyledSubTitle>

            {Expired ? (
              <ExpiredCard data-testid="expired_status">
                <IconWarning size={16} color={'red7'} />
                <ExpiredTitle>Expired</ExpiredTitle>
              </ExpiredCard>
            ) : AboutToExpire ? (
              <ExpiredCard data-testid="about_to_expire">
                <IconWarning size={16} color={'darkOrange7'} />
                <AboutExpireTitle>
                  Expiring soon: {paymentMethodDetails?.expiration_date}
                </AboutExpireTitle>
              </ExpiredCard>
            ) : (
              <StyledAboutExpireSubTitle>
                Expires: {paymentMethodDetails?.expiration_date}
              </StyledAboutExpireSubTitle>
            )}
          </div>
        </div>
      </LoadingHandler>
    </PaymentMethodStaticHeightCard>
  )
}

export default PaymentMethodCard

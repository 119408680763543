import styled from 'styled-components'
import { StaticHeightCard } from '../styles'
import IconInkDoubleDrops from '@veneer/core/dist/esm/scripts/icons/icon_ink_double_drops'

export const Container = styled.div`
  display: flex;
`
import { tokens } from '@veneer/tokens'
const { gray9, highContrastGray } = tokens.color
const { size1, lineHeight2, size0, lineHeight1, size2, family0 } =
  tokens.typography

const { size1: layoutSize1 } = tokens.layout

export const Card = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 24px;
`
export const CardTitle = styled.div`
  font-family: ${family0};
  font-weight: 400;
  font-size: ${size1};
  line-height: ${lineHeight2};
  color: ${gray9};
  margin-bottom: ${layoutSize1};
`
export const CardContent = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6px;
`
export const CardContentMedium = styled.div`
  font-family: 'Forma DJR Micro';
  font-size: ${size0};
  line-height: ${lineHeight1};
  font-weight: 400;
  letter-spacing: 0.04em;
  color: ${gray9};
  margin-bottom: ${layoutSize1};
`
export const Cardlabel = styled.p`
  margin-left: 10px;
  float: right;
`
export const AlignContent = styled.a`
  font-size: ${size1};
  line-height: ${lineHeight2};
  color: #0278ab;
`
export const CartidgeCardHeightCard = styled(StaticHeightCard)`
  & > div > section > header > h2 {
    font-size: ${size2};
    line-height: ${lineHeight2};
  }
`
export const StyledIconInkDoubleDrops = styled(IconInkDoubleDrops)`
  && {
    color: ${highContrastGray};
  }
`
export const StyledIconInkDoubleDropsSuccess = styled(IconInkDoubleDrops)`
  && {
    color: ${gray9};
  }
`

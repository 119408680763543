import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React from 'react'
import {
  CardErrorContent,
  CardErrorDescription
} from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'

const APIErrorCard: React.FunctionComponent<any> = ({ t }) => {
  const getEstimatedChargesTexts = () => {
    return [
      <>
        <div>
          <CardErrorContent>
            {t(
              'dashboard.paas.estimated-charges.error.title.paragraph-1',
              'Something went wrong.'
            )}
          </CardErrorContent>

          <CardErrorContent>
            {t(
              'dashboard.paas.estimated-charges.error.title.paragraph-2',
              'Your monthly charges did not load.'
            )}
          </CardErrorContent>
        </div>

        <CardErrorDescription>
          {t(
            'dashboard.paas.estimated-charges.error.description',
            'Please refresh the page.'
          )}
        </CardErrorDescription>
      </>
    ]
  }
  return (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<IconCalendarDay size={32} color={'gray9'} />}
            texts={getEstimatedChargesTexts()}
          />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}
export default APIErrorCard

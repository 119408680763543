import { useState } from 'react'
import {
  BillingCyclePeriodsClient,
  BillingCyclePeriodsResponse
} from '@monetization/hpaip-notification-rules-react'
import { CommonProps } from 'src/utils/common-util'

type QueryResult<T> = {
  data: T
  loading: boolean
  called: boolean
  error: Error
}

type QueryExecFunction = (
  subscriptionId: string,
  entityId: string
) => Promise<void>

export type PlanUsageResult = [
  QueryExecFunction,
  QueryResult<BillingCyclePeriodsResponse[]>
]

export const usePlanUsageRefresh = (props: CommonProps): PlanUsageResult => {
  //const ctx = usePaaSContext()
  const [called, setCalled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>(null)
  const [data, setData] = useState<BillingCyclePeriodsResponse[]>(null)
  const getPlanUsage = async (
    subscriptionId: string,
    entityId: string
  ): Promise<void> => {
    setLoading(true)
    try {
      const webClient = new BillingCyclePeriodsClient(
        props.authProvider,
        props.stack
      )

      const response = (await webClient.getPlanUsageDetails(
        props.bizlogicId,
        subscriptionId,
        entityId
      )) as BillingCyclePeriodsResponse[]

      setData(response)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
      setCalled(true)
    }
  }

  return [getPlanUsage, { data, loading, called, error }]
}

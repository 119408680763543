export const maskPhoneNumber = (number: string): string => {
  const maskedNumber = number
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  return (
    '+1-' +
    maskedNumber[1] +
    '-' +
    maskedNumber[2] +
    (maskedNumber[3] ? '-' + maskedNumber[3] : '')
  )
}

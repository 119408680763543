import { gql } from '@apollo/client'

export const GET_BILLING_DETAILS = gql`
  query BillingDetails($orderId: Int!) {
    getCitizensBillingDetails(orderId: $orderId) {
      CreditLimitAmount
      AvailableCreditAmount
      CurrentAccountBalanceAmount
      CurrentTotalBalanceAmounts
      CustomerIsDelinquent
      PaymentDueThisMonth
      PaymentDueDate
    }
  }
`

import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import { tokens } from '@veneer/tokens'
import styled, { css } from 'styled-components'
const { white, highContrastGray, gray6 } = tokens.color
const { size4, size1, lineHeight4, lineHeight2 } = tokens.typography

const { size4: layoutSize4, cornerRadius3 } = tokens.layout
interface StyledCardProps {
  $disabled?: boolean
}
const disabledStyle = css`
  && {
    pointer-events: none;
    color: ${highContrastGray};
    img {
      opacity: 0.5;
    }

    * {
      color: ${highContrastGray};
    }
  }
`

export const StyledCard = styled('div')<StyledCardProps>`
  padding: ${layoutSize4};

  background: ${white};
  border-radius: ${cornerRadius3};

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  div section > header {
    margin-bottom: 0;
  }
  ${(props) => (props.$disabled ? disabledStyle : null)}

  section {
    > header {
      > h2 {
        font-size: ${size4};
        line-height: ${lineHeight4};
      }
    }
    > article {
      @media screen and (min-width: 576px) {
        > div {
          height: auto;
        }
      }
      > div > button {
        font-size: ${size1};
        line-height: ${lineHeight2};
      }
    }
  }
`

export const StyledIconPrinter = styled(IconPrinter)`
  && {
    color: ${gray6};
  }
`

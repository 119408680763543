import { useState, useMemo } from 'react'
import { SubscriptionsClient } from '@monetization/hpaip-notification-rules-react'

function useUpdateSubscription(subscriptionId, optionId, commonProps) {
  const { authProvider, stack, bizlogicId } = commonProps
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const subscriptionsClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack),
    [authProvider, stack]
  )

  const updateSubscription = async () => {
    setLoading(true)
    setError(null)
    try {
      await subscriptionsClient.update(subscriptionId, bizlogicId, optionId)
      setLoading(false)
      return true
    } catch (e) {
      setError(e.message)
      setLoading(false)
      return false
    }
  }

  return { updateSubscription, loading, error }
}

export default useUpdateSubscription

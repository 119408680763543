import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React from 'react'
import {
  Border,
  CardContent,
  CardContentSmall,
  CardContentWraper,
  CardText,
  Cardlabel,
  CardlabelSmall,
  CardlabelText,
  FlexSpace,
  FlexSpaceBetween
} from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard, CardTitle } from 'src/components/styles'
import Tooltip from '@veneer/core/dist/esm/scripts/tooltip'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import ApiErrorCard from './APIErrorCard'
import { toCurrency } from 'src/utils/currency'

const ChargesCard: React.FunctionComponent<any> = ({
  estimatedChargeData,
  t,
  error,
  disableCardLink
}) => {
  // Render Charge function
  const renderCharge = (shouldRender, labelKey, tooltipKey, chargeAmount) => {
    if (!shouldRender) return null
    const translateWithFallback = (key, defaultValue) =>
      t(key) === key
        ? t(key, { lng: 'en_US', defaultValue })
        : t(key, defaultValue)

    return (
      <FlexSpaceBetween>
        <Cardlabel>
          {translateWithFallback(labelKey, 'Charge level')}
          <Tooltip
            content={translateWithFallback(tooltipKey, 'Tooltip content')}
            placement="top"
          >
            <IconInfo size={16} data-testid="tooltip-rollover-pages" />
          </Tooltip>
        </Cardlabel>
        <CardContent>{toCurrency(chargeAmount)}</CardContent>
      </FlexSpaceBetween>
    )
  }

  return !error ? (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={false}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<IconCalendarDay size={32} color={'gray9'} />}
            disabled={disableCardLink}
            texts={[]}
          />
        }
      >
        <div>
          <IconCalendarDay size={32} color={'gray9'} />
          <CardTitle>
            {t('dashboard.paas.estimated-charges.title', 'Monthly charges')}
          </CardTitle>

          <CardContentWraper>
            <FlexSpace>
              <CardlabelSmall>
                {t(
                  'dashboard.paas.estimated-charges.billing-period',
                  'Billing period'
                )}
                <Tooltip
                  content={t(
                    'dashboard.paas.estimated-charges.billing-period-tooltip',
                    'You will be charged a couple of days after the end of your billing cycle.'
                  )}
                  placement="top"
                >
                  <IconInfo size={16} data-testid="tooltip-rollover-pages" />
                </Tooltip>
              </CardlabelSmall>
              <CardContentSmall>
                {estimatedChargeData?.customerStartDate ||
                  estimatedChargeData?.invoiceStartDate}{' '}
                -{' '}
                {estimatedChargeData?.customerEndDate ||
                  estimatedChargeData?.invoiceEndDate}
              </CardContentSmall>
            </FlexSpace>
            <FlexSpaceBetween>
              <Cardlabel>
                {t('dashboard.paas.estimated-charges.plan-fee', 'Plan fee')}
              </Cardlabel>
              <CardContent>
                {toCurrency(estimatedChargeData?.planFee)}
              </CardContent>
            </FlexSpaceBetween>
          </CardContentWraper>
          <FlexSpaceBetween>
            <Cardlabel>
              {t(
                'dashboard.paas.estimated-charges.additinal-pages',
                'Additional pages'
              )}
              <Tooltip
                content={t(
                  'dashboard.paas.estimated-charges.additinal-pages-tooltip',
                  'Additional pages are pages printed in excess of your plan, plus any available rollover pages. They are automatically charged in sets of 10 pages.'
                )}
                placement="top"
              >
                <IconInfo size={16} data-testid="tooltip-rollover-pages" />
              </Tooltip>
            </Cardlabel>
            <CardContent>
              {toCurrency(estimatedChargeData?.additionalFee)}
            </CardContent>
          </FlexSpaceBetween>
          <>
            {renderCharge(
              estimatedChargeData?.cancellationFee !== 0,
              'dashboard.paas.estimated-charges.cancellation-fee',
              'dashboard.paas.estimated-charges.cancellation-fee-tooltip',
              estimatedChargeData?.cancellationFee
            )}

            {renderCharge(
              estimatedChargeData?.overdueFee !== 0,
              'dashboard.paas.estimated-charges.overdue-charges',
              'dashboard.paas.estimated-charges.overdue-fee-tooltip',
              estimatedChargeData?.overdueFee
            )}
          </>

          <Border />
          <FlexSpace>
            <CardlabelText>
              {t(
                'dashboard.paas.estimated-charges.current-total',
                'Current total'
              )}
            </CardlabelText>
            <CardText>{toCurrency(estimatedChargeData?.total)}</CardText>
          </FlexSpace>
        </div>
      </LoadingHandler>
    </EstimatedChargesHeightCard>
  ) : (
    <ApiErrorCard t={t} />
  )
}
export default ChargesCard

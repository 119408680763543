import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { CartridgeStatusTypes } from './types'

export class FetchCatridgeStatus extends StratusClient {
  constructor(authToken: JarvisAuthProvider) {
    super('', authToken)
  }

  getCatridgeStatusData(URL: string): Promise<CartridgeStatusTypes[]> {
    return this.jarvisWebHttpInstance
      .get({
        url: URL
      })
      .then(({ data }) => data)
      .then((res) => {
        const {
          state: {
            reported: {
              cdmData: { supplyStates }
            }
          }
        } = res

        const catrigeStatusList: CartridgeStatusTypes[] = []

        for (const key in supplyStates) {
          const catrigeStatusObj: CartridgeStatusTypes = {
            deviceAssessment: '',
            supplyState: '',
            levelState: '',
            cartridgeName: '',
            shippmentDate: '',
            externalLink: ''
          }
          catrigeStatusObj.deviceAssessment = supplyStates[key].deviceAssessment
          catrigeStatusObj.supplyState = supplyStates[key].supplyState
          catrigeStatusObj.levelState = supplyStates[key].levelState
          catrigeStatusObj.cartridgeName = supplyStates[key].colorCode
          catrigeStatusObj.shippmentDate = '2023-01-30'
          catrigeStatusList.push(catrigeStatusObj)
        }
        return catrigeStatusList
      })
  }
}

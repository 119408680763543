import { ApolloProvider } from '@apollo/client'

import ToastProvider from '@veneer/core/dist/esm/scripts/toast_container/toast_provider'
import React from 'react'
import { createApolloClient } from '../../api/adapters'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'

import PrinterMainComponent from '../PrinterMainComponent'
import getEnvironmentVariables from '../../helpers/getEnvironmentVariables'
import { getStack } from '../../helpers/getStack'

const Root = ({
  t,
  authProvider,
  analytics,
  stack,
  localization,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const graphqlClient = createApolloClient(authProvider, stack)
  const envVariables = getEnvironmentVariables(getStack(stack))
  const bizlogicId = envVariables.variables.BL_ID
  //This will be use for multiple subscription later
  // const [index, setIndex] = useState<number>(0)
  return (
    <ToastProvider>
      <ApolloProvider client={graphqlClient}>
        <PrinterMainComponent
          commonProps={{
            authProvider,
            stack,
            t,
            analytics,
            localization,
            navigation,
            bizlogicId
          }}
        />
      </ApolloProvider>
    </ToastProvider>
  )
}

export default Root

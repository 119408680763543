import { useEffect, useState } from 'react'
import { useReturnDetails } from '../hooks/useReturnDetails'

const useDeviceReturnDetails = (
  tenantId,
  fulfillmentOrderId,
  serialNumber,
  authProvider,
  stack
) => {
  const { getDeviceReturnDetails } = useReturnDetails(authProvider, stack)

  const [deviceReturnStatus, setDeviceReturnStatus] = useState<any>()

  useEffect(() => {
    const fetchReturnDetailsData = async () => {
      try {
        const deviceReturnDetails = await getDeviceReturnDetails(
          tenantId,
          fulfillmentOrderId,
          serialNumber
        )
        setDeviceReturnStatus(deviceReturnDetails)
      } catch (error) {
        console.error('Error fetching return details:', error)
        setDeviceReturnStatus(null)
      }
    }

    if (tenantId && fulfillmentOrderId && serialNumber) {
      fetchReturnDetailsData()
    }
  }, [
    getDeviceReturnDetails,
    tenantId,
    fulfillmentOrderId,
    serialNumber,
    authProvider,
    stack
  ])

  return deviceReturnStatus
}

export default useDeviceReturnDetails

import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import {
  CardErrorContent,
  ErrorRemorse,
  ErrorRemorseIcon
} from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'
import { IconPrinterX } from '@veneer/core/dist/esm/scripts/icons'
import moment from 'moment'
import { toCurrency } from 'src/utils/currency'

const PrinterNotReceivedCard: React.FunctionComponent<any> = ({
  t,
  returnDetails,
  invoiceAmountData
}) => {
  const getEstimatedChargesTexts = () => {
    return [
      <ErrorRemorse key="1">
        <ErrorRemorseIcon>
          <IconPrinterX size={32} color={'gray9'} />
        </ErrorRemorseIcon>
        <CardErrorContent>
          {t('dashboard.paas.estimated-charges.error.cancelledDecsTwo', {
            date: moment(returnDetails?.returnTimeStamp).format('MMM DD, YYYY'),
            cancellationFee: toCurrency(invoiceAmountData?.[0]?.totalAmount),
            defaultValue:
              'The printer was not returned by {{date}}. You have been charged a cancellation fee of {{cancellationFee}} at the time of cancellation.'
          })}
        </CardErrorContent>
      </ErrorRemorse>
    ]
  }
  return (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError forceHeight={false} texts={getEstimatedChargesTexts()} />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}
export default PrinterNotReceivedCard

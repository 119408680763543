import React, { useEffect, useState } from 'react'
import {
  SubscriptionsStateClient,
  Subscription
} from '@monetization/hpaip-notification-rules-react'
import InitialComponent from '../InitialComponent'
import { CommonProps } from 'src/utils/common-util'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
interface Props {
  commonProps: CommonProps
}

const PrinterMainComponent: React.FunctionComponent<Props> = ({
  commonProps
}) => {
  const [subscriptionId, setSubscriptionId] = useState<string>(null)
  const [subscriptionsData, setSubscriptionData] = useState<Subscription>(null)
  const { authProvider, stack, t } = commonProps
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [key, setKey] = useState(0)
  const [index, setIndex] = useState<number>(
    Number(localStorage.getItem('printerIndex')) || 0
  )
  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true)
      try {
        const subscriptionsClient = new SubscriptionsStateClient(
          authProvider,
          stack
        )
        const subscriptionsData =
          await subscriptionsClient.getAllSubscriptions()
        setSubscriptionData(subscriptionsData)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
        setError(true)
        //setError('Failed to fetch subscriptions')
      } finally {
        //setLoading(false)
      }
    }

    fetchSubscriptions()
  }, [authProvider, stack])

  useEffect(() => {
    if (subscriptionsData?.length > 0) {
      setSubscriptionId(subscriptionsData[index]?.subscriptionId)
    }
  }, [index, subscriptionsData])

  const refreshPrinterSelection = () => {
    setKey((prevKey) => prevKey + 1)
  }

  return (
    <div key={key}>
      {subscriptionId ? (
        <InitialComponent
          subscriptionId={subscriptionId}
          subscriptionsData={subscriptionsData}
          commonProps={commonProps}
          refreshPrinterSelection={refreshPrinterSelection}
          setIndex={setIndex}
          index={index}
        />
      ) : (
        <>
          <LoadingHandler
            loading={loading}
            error={error}
            customError={
              <ErrorSection
                t={t}
                title="Something went wrong"
                description="Your account information did not load."
                buttonText="Try again"
              />
            }
          ></LoadingHandler>
        </>
      )}
    </div>
  )
}

export default PrinterMainComponent

import {
  NotificationBar,
  SectionHeader
} from '@monetization/hpaip-ui-shared-components'
import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
const containerMaxWidth = '1480px'

const { gray12 } = tokens.color
const { family0, size4, lineHeight4 } = tokens.typography

const {
  size5: layoutSize5,
  size4: layoutSize4,
  size8: layoutSize8
} = tokens.layout

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${layoutSize4};
  margin-left: ${layoutSize4};
  margin-right: ${layoutSize4};
  @media(max-width :375px){
    0 16px ;
  }
  @media (min-width: 500px) {
    margin: 0 20px ;
  }

  @media (min-width: 641px) {
    margin: 0 32px ;
  }

  @media(min-width:1280px){
    margin: 0 40px ;
  }
`

export const ContainerSupportDevice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`

export const DashboardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: ${layoutSize4};
`

export const StyledSectionHeader = styled(SectionHeader)`
  & {
    margin-top: 0px !important;
    h2 {
      padding-left: 0;
    }
  }
`

export const StyledNotificationBar = styled(NotificationBar)`
  white-space: break-spaces;
  margin-bottom: 1px;

  p + p {
    margin-top: ${layoutSize4};
  }
`
export const StyledPage = styled.div`
  @media only screen {
    max-width: ${containerMaxWidth};
  }
`
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const CardPrinter = styled.div`
  padding-top: ${layoutSize5};
  padding-right: ${layoutSize4};
  padding-left: ${layoutSize4};
  padding-bottom: 40px;
  @media (min-width: 500px) {
    padding-top: ${layoutSize5};
    padding-right: ${layoutSize5};
    padding-left: ${layoutSize5};
    padding-bottom: 40px;
  }
  @media (min-width: 641px) {
    padding-top: ${layoutSize8};
    padding-right: ${layoutSize8};
    padding-left: ${layoutSize8};
    padding-bottom: 40px;
  }
  @media (min-width: 1200px) {
    padding: 40px;
  }
`
export const CardDeviceDetailsTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size4};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight4};
  margin-top: 24px;
`

export const DropDownStyle = styled.p`
  float: right;
`

import { ProgressBar } from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import { AdditionalProgressText } from '../styles'
import { CUSTOM_COLORS } from '../../../__mocks__/constants/styles.constants'
import { CommonProps } from 'src/utils/common-util'

interface AdditionalProgressProps {
  printPlanOveragePagesPrinted: number
  printPlanOveragePageBlocks: number
  monthlyOveragePageBlockSize: number
  commonProps: CommonProps
}

export const AdditionalProgress: React.FC<AdditionalProgressProps> = ({
  printPlanOveragePagesPrinted,
  printPlanOveragePageBlocks,
  monthlyOveragePageBlockSize,
  commonProps
}) => {
  const { t } = commonProps

  const progressNameColor = CUSTOM_COLORS.colorOrange
  return (
    <div>
      <ProgressBar
        t={t}
        progressNameColor={progressNameColor}
        color={
          printPlanOveragePagesPrinted <= 0
            ? 'gray1'
            : CUSTOM_COLORS.colorOrange
        }
        total={printPlanOveragePageBlocks * monthlyOveragePageBlockSize}
        current={printPlanOveragePagesPrinted}
        label={t('usage-data-progress.additional.label', 'Additional pages')}
        tip={t(
          'usage-data-progress.additional.tip',
          'You can add page sets to your account if you need to print more than your plan and rollover pages allow.'
        )}
      />
      <AdditionalProgressText>
        {t('usage-data-progress.additional.text', {
          sets: printPlanOveragePageBlocks,
          pages: monthlyOveragePageBlockSize,
          defaultValue:
            'You have purchased {{sets}} set of additional pages ({{pages}} pages/set).'
        })}
      </AdditionalProgressText>
    </div>
  )
}

import { useCallback, useMemo } from 'react'

import { ReturnStateClient } from '@monetization/hpaip-notification-rules-react'

import type { RetrieveReturnResponse } from '@monetization/hpaip-notification-rules-react'

export const useReturnDetails = (authProvider, stack) => {
  const returnsClient = useMemo(
    () => new ReturnStateClient(authProvider, stack),
    [authProvider, stack]
  )

  const getDeviceReturnDetails = useCallback(
    async (
      tenantId: string,
      fulfillmentOrderId: string,
      serialNumber: string
    ): Promise<Partial<RetrieveReturnResponse>> => {
      try {
        const returnsList = await returnsClient.getReturnDetails(tenantId)
        //getting the return by using fulfillmentOrderId
        const filteredReturnList = returnsList?.data?.filter(
          (item) => item.fulfillmentOrderId === fulfillmentOrderId
        )
        let status
        let returnTimeStamp
        let isFedxDisplay
        if (filteredReturnList.length > 0) {
          const returnStatus = filteredReturnList[0].parts.filter(
            (item) => item.serialNumber === serialNumber
          )
          if (returnStatus) {
            status = returnStatus[0].status
            returnTimeStamp = returnStatus[0].timeStamp
            isFedxDisplay = doesNotContainItemReceived(returnStatus[0])
          }
        }
        // returning only the return status value
        return {
          status: status,
          timeStamp: filteredReturnList[0].createdAt,
          timeoutInDays: filteredReturnList[0].timeoutInDays,
          returnTimeStamp: returnTimeStamp,
          returnNumber: filteredReturnList[0].returnNumber,
          isFedxDisplay: isFedxDisplay
        }
      } catch (error) {
        return {
          status: null
        }
      }
    },
    [returnsClient]
  )

  // Function to check if history contains "itemReceived"
  function doesNotContainItemReceived(part: any): boolean {
    return !part.history.some((entry) => entry.status === 'itemReceived')
  }

  return { getDeviceReturnDetails }
}

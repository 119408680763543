import { Card, Link } from '@monetization/hpaip-ui-shared-components'
import { tokens } from '@veneer/tokens'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
import IconExternalLink from '@veneer/core/dist/esm/scripts/icons/icon_external_link'
import styled, { css } from 'styled-components'
import { InternalLink } from './InternalLink'
import { CUSTOM_COLORS } from '../__mocks__/constants/styles.constants'

const { highContrastGray, gray9, hpBlue7 } = tokens.color
const { family0, size3, size4, lineHeight3, lineHeight4, size1, lineHeight2 } =
  tokens.typography

const {
  smMax,
  smMin,
  size1: layoutSize1,
  size4: layoutSize4,
  size6: layoutSize6,
  size3: layoutSize3,
  size5
} = tokens.layout

export interface CardWithDisableProps {
  $disabled?: boolean
  $suspendedORExpired?: boolean
  $overDueORAboutToExpire?: boolean
}
export const CardWithDisable = styled(Card)<CardWithDisableProps>`
  ${({ $disabled }) =>
    $disabled
      ? `
        pointer-events: none;
        color: ${highContrastGray} !important;

        * {
          color: ${highContrastGray} !important;
        }
      `
      : null}
`
export const SupportCard = styled(CardWithDisable)<CardWithDisableProps>`
  height: auto;
  margin-top: 24px;
  padding: ${layoutSize4} !important;
  & > div > footer {
    margin-top: ${layoutSize1};
  }
  & > div > section > header > h2 {
    font-size: ${size3};
    line-height: ${lineHeight3};
  }
`

export const SupportDetailsCard = styled(Card)`
  height: auto;
  gap: 0px;
  flex-shrink: 0;
  padding: ${layoutSize4} !important;

  & > div > footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;
  }
  & > div > section > header {
    margin-bottom: 0;
    > h2 {
      font-size: ${size4};
      line-height: ${lineHeight4};
      margin-bottom: 16px;
    }
  }
`

export const StaticHeightCard = styled(CardWithDisable)<CardWithDisableProps>`
  min-height: 262px !important;
  padding: ${layoutSize4} !important;
`
export const BillingStaticHeightCard = styled(StaticHeightCard)`
  & > div > section > article {
    height: 100%;
  }
  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div > section > header {
    margin-bottom: ${layoutSize1};
  }
  & > div > footer > dl:nth-child(1) {
    display: none;
  }
  & > div > footer > dl:nth-child(2) {
    margin-top: 0px;
  }
`
export const EstimatedChargesHeightCard = styled(StaticHeightCard)`
  & > div > section > article {
    height: 100%;
  }
  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
export const CardTitle = styled.p`
  color: ${gray9};
  font-size: ${size4};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight4};
`
export const DarkCard = styled(Card)`
  background-color: #f8f8f8 !important;
  box-sizing: border-box !important;
`
export const StyledDeviceDetailsCard = styled(DarkCard)`
  @media screen and (max-width: ${smMax}) {
    display: block;
  }
  min-height: 296px;
`
export const FooterDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  padding: 0px 0px 7px 0px;
  gap: 0px;
  opacity: 0px;
  font-size: ${size1};
  font-weight: 400;
  line-height: 20px;
  align: left;
`

type StyledCardContainerProps = {
  $isLoading?: boolean
}

export const StyledCardContainer = styled(
  StyledDeviceDetailsCard
)<StyledCardContainerProps>`
  && {
    padding: ${layoutSize6};
    background: #f8f8f8 !important;
    min-height: 296px;
    display: flex;
    flex-grow: 1;
    height: 100%;
    ${({ $isLoading }) =>
      $isLoading
        ? 'padding: 0;'
        : `@media screen and (max-width: ${smMin}) {
            display: flex;
            flex-direction: column;
          }`}
  }
`

const linkStyles = css`
  font-size: ${size1}!important;
  line-height: ${lineHeight2}!important;
  display: flex !important;
  width: 100% !important;
  align-items: center;
  justify-content: space-between;
  color: ${CUSTOM_COLORS.colorDeepBlue};
  cursor: pointer;
  &:focus {
    border: 1px solid #0278ab;
    outline: none;
  }
`

export const CardExternalLink = styled(Link)`
  ${linkStyles}
`
export const CardExternalLinkPreArival = styled(Link)`
  ${linkStyles}
  justify-content: normal;
`
export const PrearrivalContent = styled.p`
  font-family: ${family0};
  padding: ${layoutSize3} 0px;
  color: ${gray9};
`

export const CardLink = styled(InternalLink)`
  ${linkStyles}

  ${({ disabled }) =>
    !!disabled &&
    css`
      pointer-events: none;
      color: ${highContrastGray} !important;
      & > svg {
        color: ${highContrastGray} !important;
      }
    `}
`
export const SupportStyledLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgb(219, 219, 219);
  > div > svg {
    color: ${CUSTOM_COLORS.colorDeepBlue};
  }
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
  &:focus {
    border: 1px solid ${hpBlue7};
    outline: none;
  }
`

export const ChevronRightIcon = styled(IconChevronRight)`
  color: ${CUSTOM_COLORS.colorDeepBlue}!important;
  ${({ disabled }) =>
    !!disabled &&
    css`
      pointer-events: none;
      color: ${highContrastGray} !important;
      & > svg {
        color: ${highContrastGray} !important;
      }
    `}
`

export const ExternalLinkIcon = styled(IconExternalLink)`
  color: #0278ab !important;
  margin-bottom: 2px;
`
export const ExternalLinkIconPreArival = styled(IconExternalLink)`
  color: #0278ab !important;
  margin-left: ${layoutSize3};
  margin-bottom: 2px;
`
export const StyledLink = styled(InternalLink)`
  && {
    margin-bottom: ${layoutSize1};
  }
  &:focus {
    border: 1px solid #0278ab;
    outline: none;
  }
`
export const CardErrorContent = styled.p`
  color: ${gray9};
  font-family: ${family0};
`
export const CardErrorDescription = styled.p`
  color: ${gray9};
  font-family: ${family0};
  margin-top: ${size5};
`
export const CardLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  line-height: 20px;
`

import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { vividSkyBlue0, gray12, gray9, hpBlue7 } = tokens.color
const { size0, size1, size3, size4, size6, size8, smMax } = tokens.layout
const {
  family0,
  size4: fontSize4,
  lineHeight4,
  lineHeight3,
  lineHeight2,
  size1: fontSize1,
  size2: fontSize2
} = tokens.typography
export const FedexCard = styled.div`
  margin: ${size0} ${size8} 40px ${size8};
  padding: ${size6} ${size4};
  border-radius: ${size4};
  background: ${vividSkyBlue0};
  font-family: ${family0};
`
export const Container = styled.div`
  display: flex;
  gap: ${size3};
  @media screen and (max-width: ${smMax}) {
    display: block;
  }
`
export const Title = styled.p`
  font-size: ${fontSize4};
  line-height: ${lineHeight4};
  color: ${gray12};
  margin-bottom: ${size3};
  @media screen and (max-width: ${smMax}) {
    margin-top: ${size1};
    margin-bottom: ${size4};
  }
`
export const Description = styled.p`
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${gray9};
`

export const Link = styled.a`
  margin-top: ${size1};
  font-size: ${fontSize1};
  line-height: 18px;
  color: ${hpBlue7};
  text-decoration: underline;
`
export const ContentBlock = styled.div`
  display: flex;
  margin-top: ${size6};
  @media screen and (max-width: ${smMax}) {
    display: block;
  }
`
export const ContentCard = styled.div`
  width: fit-content;
`
export const FooterContent = styled.div`
  margin-top: ${size6};
  font-size: ${fontSize2};
  line-height: ${lineHeight3};
  color: ${gray9};
`

import styled from 'styled-components'
import { StaticHeightCard } from '../styles'
import { tokens } from '@veneer/tokens'
const { gray9 } = tokens.color
const { size2, family0, lineHeight2 } = tokens.typography

const { size1: layoutSize1, size2: layoutSize2 } = tokens.layout
export const CitizensPayIcon = styled.img<{
  src?: string
  alt?: string
  width?: string
  height?: string
}>`
  max-width: 100%;
  margin-bottom: ${layoutSize1};
  margin-top: ${layoutSize2};
`

export const CitizensStaticHeightCard = styled(StaticHeightCard)`
  & > div > section > article {
    height: 100%;
  }
  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div > section > article {
    margin-top: ${layoutSize2};
  }

  & > div > section > header > h2 {
    font-size: ${size2};
    line-height: ${lineHeight2};
  }
`
export const CardErrorContent = styled.p`
  font-family: ${family0};
  color: ${gray9};
`

import React from 'react'
import {
  method,
  multi,
  TranslatorFunctionType
} from '@monetization/hpaip-ui-shared-components'
import {
  isBetweenDates,
  SubscriptionStateEnum,
  BillingCyclePeriods,
  BillingCyclePeriodsResponse,
  UsageDataSummary
} from '@monetization/hpaip-notification-rules-react'

export const getCurrentPeriod = (
  periods: BillingCyclePeriodsResponse[]
): BillingCyclePeriods | undefined => {
  const items = periods.reduce(
    (initial, { items }) => [...initial, ...(items as BillingCyclePeriods[])],
    [] as BillingCyclePeriods[]
  )

  return items.find((item) =>
    isBetweenDates(new Date(item.startDate), new Date(item.endDate))
  )
}

export const getTotalPages = (data?: UsageDataSummary) => {
  if (!data) return 0

  return Object.entries(data as unknown as Record<string, number>).reduce(
    (initial, [key, value]) => {
      if (/printed/gi.test(key)) {
        return initial + value
      }
      return initial
    },
    0
  )
}

export const getErrorMessages = (
  status: string,
  t: TranslatorFunctionType
): string[] => {
  const dispatcher = multi(
    (s) => s,
    method(SubscriptionStateEnum.PENDING, () => [
      t(
        'dashboard.paas.monthly-plan-summary.pre-arrival-message',
        'Your monthly plan summary will be displayed here after you set up your printer'
      )
    ]),
    method(SubscriptionStateEnum.CANCELED, () => [
      t(
        'dashboard.paas.monthly-plan-summary.canceled-message',
        'Monthly plan summary is no longer available'
      )
    ]),
    method(SubscriptionStateEnum.CANCELING, () => [
      t(
        'dashboard.paas.monthly-plan-summary.canceled-message',
        'Monthly plan summary is no longer available'
      )
    ])
  )(() => [
    <>
      <p>
        {t(
          'dashboard.paas.monthly-plan-summary.error.title.paragraph-1',
          'Something went wrong.'
        )}
      </p>
      <p>
        {t(
          'dashboard.paas.monthly-plan-summary.error.title.paragraph-2',
          'Your monthly plan summary did not load.'
        )}
      </p>
    </>,
    t(
      'dashboard.paas.monthly-plan-summary.error.description',
      'Please refresh the page.'
    )
  ])

  return dispatcher(status)
}

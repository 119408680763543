import styled, { css } from 'styled-components'

import { tokens } from '@veneer/tokens'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
import { InternalLink } from '../InternalLink'
import { StaticHeightCard } from '../styles'
import IconPin from '@veneer/core/dist/esm/scripts/icons/icon_pin'
import IconCreditCard from '@veneer/core/dist/esm/scripts/icons/icon_credit_card'
import { CUSTOM_COLORS } from '../../__mocks__/constants/styles.constants'
const { gray12, gray9, red7, highContrastGray, darkOrange5 } = tokens.color
const { family0, size4, size0, size1, lineHeight4, lineHeight2, lineHeight1 } =
  tokens.typography

const { size2: layoutSize2, size5 } = tokens.layout
interface ExpiredCardProps {
  children: React.ReactNode
  'data-testid': string
}

export const PaymentMethodStaticHeightCard = styled(StaticHeightCard)`
  border: ${({ $suspendedORExpired, $overDueORAboutToExpire }) => {
    if ($suspendedORExpired) {
      return `1px solid ${red7} !important`
    }
    if ($overDueORAboutToExpire) {
      return `1px solid ${darkOrange5} !important`
    }
    return 'transparent'
  }};

  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  section {
    justify-content: space-between;
    > header {
      > h2 {
        color: ${gray12};
        font-size: ${size4};
        line-height: ${lineHeight4};
      }
    }
  }

  > div > section {
    > article {
      height: auto;
    }
  }
  footer {
    padding: 8px 0 0 0;
  }
`
const linkStyles = css`
  font-size: ${size1}!important;
  line-height: ${lineHeight2}!important;
  display: flex !important;
  width: 100% !important;
  align-items: center;
  justify-content: space-between;
  color: #0278ab;
  cursor: pointer;
  &:focus {
    border: 1px solid #0278ab;
    outline: none;
  }
`
export const CardLink = styled(InternalLink)`
  ${linkStyles}
`
export const StyledImage = styled.img<{ src?: string; alt?: string }>`
  margin: ${layoutSize2} 0 ${layoutSize2};
  width: 40px;
  height: 28px;
`
export const StyledTitle = styled.p`
  margin-bottom: 4px;
  font-weight: 400;
  font-family: ${family0};
  color: ${gray12};
  font-size: ${size0};
  line-height: ${lineHeight1};
`
export const StyledSubTitle = styled.p`
  font-family: ${family0};
  color: ${gray12};
  font-size: ${size0};
  line-height: ${lineHeight1};
  font-weight: 400;
  margin-bottom: 2px;
  :last-child {
    margin-bottom: 0;
  }
`
export const StyledAboutExpireSubTitle = styled.p`
  font-family: ${family0};
  color: ${gray12};
  font-size: ${size1};
  line-height: ${lineHeight2};
  font-weight: 400;
  margin-bottom: 2px;
  :last-child {
    margin-bottom: 0;
  }
`
export const ChevronRightIcon = styled(IconChevronRight)`
  color: #0278ab !important;
  ${({ disabled }) =>
    !!disabled &&
    css`
      pointer-events: none;
      color: ${highContrastGray} !important;
      & > svg {
        color: ${highContrastGray} !important;
      }
    `}
`
export const StyledIconPin = styled(IconPin)`
  && {
    color: ${gray9};
  }
`
export const StyledIconCreditCard = styled(IconCreditCard)`
  && {
    color: ${gray9};
  }
`
export const CardLinkText = styled.span`
  color: ${CUSTOM_COLORS.colorDeepBlue};
`
export const ExpiredCard = styled.div<ExpiredCardProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 2px;
`
export const ExpiredTitle = styled.p`
  font-family: ${family0};
  color: ${red7};
  font-size: ${size1};
  line-height: ${lineHeight2};
  font-weight: 400;
`
export const AboutExpireTitle = styled.p`
  font-family: ${family0};
  color: #b2600c;
  font-size: ${size1};
  line-height: ${lineHeight2};
  font-weight: 400;
`
export const CardErrorDescription = styled.p`
  color: ${gray9};
  font-family: ${family0};
  margin-top: ${size5};
`
export const CardErrorContent = styled.p`
  color: ${gray9};
  font-family: ${family0};
`

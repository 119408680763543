import React, { useEffect, useState } from 'react'

import {
  CartridgeStatus,
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import { FetchCatridgeStatus } from '../../api/services/cartridgeStatus/cartridgeStatus'
import { ChevronRightIcon } from '../styles'

import {
  Cardlabel,
  AlignContent,
  StyledIconInkDoubleDrops,
  CartidgeCardHeightCard,
  StyledIconInkDoubleDropsSuccess
} from './CartridgeStatusCard.style'

import { CartridgeStatusTypes } from '../../api/services/cartridgeStatus/types'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import sendOpenTelemetryEvent from 'src/helpers/sendOpenTelemetryEvent'
import { CommonProps } from 'src/utils/common-util'

interface PrinterProps {
  printer: any
  commonProps: CommonProps
}

export const CartridgeStatusCard: React.FunctionComponent<PrinterProps> = ({
  printer,
  commonProps
}) => {
  const { authProvider, t } = commonProps
  const [cartridgeData, setCartridgeData] = useState<CartridgeStatusTypes[]>([])
  const [errorAPI, setErrorAPI] = useState<boolean>(false)
  const [loading, isLoading] = useState<boolean>(true)
  const preArrivalState = printer?.state === SubscriptionStateEnum.PENDING
  useEffect(() => {
    setErrorAPI(false)
    async function getCatridgeStautus() {
      try {
        const res = new FetchCatridgeStatus(authProvider)
        const data = await res.getCatridgeStatusData(
          'https://user1675060654003.requestly.dev/vasari'
        )
        setCartridgeData(data)
        isLoading(false)
      } catch (error) {
        if (error?.response?.status === 404) return setCartridgeData(null)
        else {
          sendOpenTelemetryEvent(
            'error in FetchCatridgeStatus: ' + JSON.stringify(error)
          )
        }
        setErrorAPI(true)
        isLoading(false)
      }
    }
    getCatridgeStautus()
  }, [authProvider])
  const isError = Boolean(errorAPI || preArrivalState || !isLoading)

  const shouldShowFooter = !isError && !loading

  return (
    <CartidgeCardHeightCard
      data-testid="cartridge-status-card"
      icon={<StyledIconInkDoubleDropsSuccess />}
      title={t(
        'hp-instant-ink-cartridge.cartridge-title',

        'Ink cartridge status'
      )}
      footer={
        shouldShowFooter && (
          <a
            href="https://www.hp.com/us-en/hp-information/recycling/ink-toner.html"
            target="_blank"
            rel="noreferrer"
          >
            <AlignContent>
              {t(
                'hp-instant-ink-cartridge.footer',

                'Order a cartridge recycling envelope'
              )}
            </AlignContent>

            <Cardlabel>
              <ChevronRightIcon />
            </Cardlabel>
          </a>
        )
      }
    >
      <LoadingHandler
        loading={loading}
        error={isError}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            icon={<StyledIconInkDoubleDrops size={72} />}
            texts={
              preArrivalState
                ? [
                    t(
                      'hp-instant-ink-cartridge.pre-arrival-title',
                      'Your ink cartridge status will be available here after you set up your printer.'
                    )
                  ]
                : [
                    t(
                      'hp-instant-ink-cartridge.Api-error-title',
                      'Something went wrong. Your ink cartridge status did not load.'
                    ),
                    t(
                      'hp-instant-ink-cartridge.Api-error-description',
                      'Please refresh the page.'
                    )
                  ]
            }
          />
        }
      >
        <CartridgeStatus
          t={t}
          cartridgeData={cartridgeData}
          data-testid="loaded-status"
        />
      </LoadingHandler>
    </CartidgeCardHeightCard>
  )
}
export default CartridgeStatusCard

import React, { FC } from 'react'

import { PeriodBlock } from '../styles'
import { getFormattedDate } from 'src/helpers/dateTime'

interface MonthlyPlanPeriodProps {
  startDate: string
  endDate: string
}

const MonthlyPlanPeriod: FC<MonthlyPlanPeriodProps> = (
  props: MonthlyPlanPeriodProps
) => {
  const { startDate, endDate } = props
  return (
    <PeriodBlock>
      <span data-testid="monthly-current-period">
        {`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}
      </span>
    </PeriodBlock>
  )
}

export default MonthlyPlanPeriod

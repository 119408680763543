import { useState, useEffect } from 'react'
import { FulfillmentClient } from '@monetization/hpaip-notification-rules-react'
import { FulfillmentStatus } from '../../src/__mocks__/constants/fulfillemnt.constants'
import sendOpenTelemetryEvent from 'src/helpers/sendOpenTelemetryEvent'

const useFulfillmentOrders = (
  authProvider,
  stack,
  fulfillmentOrderId,
  printer
) => {
  const [hasInvoicedData, setHasInvoicedData] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const FulfillmentOrdersAPIClient = new FulfillmentClient(
          authProvider,
          stack
        )
        const data =
          await FulfillmentOrdersAPIClient.getFulfilmentOrderByFulfillmentOrderId(
            fulfillmentOrderId
          )
        const { parts } = data[0] ?? {}
        const filteredData = parts?.filter(
          (item) => item.serialNumber === printer?.entityDetails?.serialNumber
        )
        const invoicedData = filteredData
          ?.flatMap((item) => item.history)
          ?.filter((item) => item.status === FulfillmentStatus.INVOICED)
        setHasInvoicedData(
          invoicedData?.length > 0 ||
            filteredData[0]?.status == FulfillmentStatus.INVOICED
        )
      } catch (error) {
        if (error?.response?.status === 404) return setHasInvoicedData(null)
        else {
          sendOpenTelemetryEvent(
            'Error in FulfillmentOrdersAPIClient: ' + JSON.stringify(error)
          )
        }
        setHasInvoicedData(false)
      }
    }

    fetchData()
  }, [authProvider, stack, fulfillmentOrderId, printer])

  return hasInvoicedData
}

export default useFulfillmentOrders

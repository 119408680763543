import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import IconPin from '@veneer/core/dist/esm/scripts/icons/icon_pin'
import { CUSTOM_COLORS } from '../../__mocks__/constants/styles.constants'

export const CardLinkText = styled.span`
  color: ${CUSTOM_COLORS.colorDeepBlue};
`

export const Container = styled.div`
  text-transform: capitalize;
`

const { highContrastGray } = tokens.color

export const StyledIconPin = styled(IconPin)`
  && {
    color: ${highContrastGray};
  }
`

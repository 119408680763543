import moment from 'moment'

export const getFormattedDate = (date: string, format = 'MMM DD'): string => {
  if (!moment(date, moment.ISO_8601).isValid()) {
    return 'Invalid date'
  }
  return moment(date?.replace('Z', '')).format(format)
}

export const getFormattedDateSync = (date: string): string => {
  if (!moment(date, moment.ISO_8601).isValid()) {
    return 'Invalid date'
  }
  return moment(date).format('MM/DD/YY hh:mma')
}

import {
  AddressDetails,
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  navigationLinks,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import IconPin from '@veneer/core/dist/esm/scripts/icons/icon_pin'
import React, { useMemo } from 'react'
import { maskPhoneNumber } from '../../utils/maskPhoneNumber'
import { CardLink, ChevronRightIcon, CardLinkWrapper } from '../styles'
import { Container, StyledIconPin } from './ShippingDetailsCard.styles'
import { ShipppingDetailsStaticHeightCard } from './styles'
import { isDeliquency } from '../../utils/common-util'
import { CommonProps } from 'src/utils/common-util'

interface PrinterProps {
  printer: any
  isLoading: any
  deliquencyNotification: any
  commonProps: CommonProps
  shippingAddress?: any
  called: boolean
  loading: boolean
  error?: any
}

const ShippingDetailsCard: React.FunctionComponent<PrinterProps> = ({
  printer,
  deliquencyNotification,
  isLoading: subscriptionIsLoading,
  commonProps,
  shippingAddress,
  called,
  loading,
  error
}) => {
  const { t } = commonProps

  const { shippingInformation } = navigationLinks

  const { disableCard, disableCardLink } = useMemo(() => {
    const disableCardState = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING
    ]
    const disableCardLinks = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING,
      SubscriptionStateEnum.PENDING
    ]
    if (subscriptionIsLoading || !printer)
      return { disableCard: true, disableCardLink: true }
    return {
      disableCard: disableCardState.includes(printer.state),
      disableCardLink: disableCardLinks.includes(printer.state)
    }
  }, [subscriptionIsLoading, printer])

  const maskedPhoneNumber =
    shippingAddress?.countryCode?.toLowerCase() === 'us'
      ? shippingAddress?.phoneNumber &&
        maskPhoneNumber(shippingAddress?.phoneNumber)
      : shippingAddress?.phoneNumber

  const isLoading =
    subscriptionIsLoading || loading || (!called && !loading) || !called

  const shouldShowFooter = !isLoading && !error
  const isActive = isDeliquency(deliquencyNotification)

  return (
    <ShipppingDetailsStaticHeightCard
      icon={<IconPin color={'gray9'} />}
      title={t('dashboard.paas.shipping-details.title', 'Shipping address')}
      $disabled={isLoading ? false : (disableCard && !error) || isActive}
      footer={
        shouldShowFooter && (
          <CardLink
            commonProps={commonProps}
            path={shippingInformation}
            disabled={disableCardLink}
            text={
              <CardLinkWrapper id="manage-address-button">
                {t(
                  'dashboard.paas.shipping-details.manage-address',
                  'Manage address'
                )}

                <ChevronRightIcon disabled={disableCardLink} />
              </CardLinkWrapper>
            }
          />
        )
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={Boolean(error)}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<StyledIconPin size={32} />}
            texts={[
              <>
                <p>
                  {t(
                    'dashboard.paas.shipping-details.error.title.paragraph-1',
                    'Something went wrong.'
                  )}
                </p>
                <p>
                  {t(
                    'dashboard.paas.shipping-details.error.title.paragraph-2',
                    'Your location information did not load.'
                  )}
                </p>
              </>,
              t(
                'dashboard.paas.shipping-details.error.description',
                'Please refresh the page.'
              )
            ]}
          />
        }
      >
        <Container>
          <AddressDetails
            contactName={`${shippingAddress?.firstName} ${shippingAddress?.lastName}`}
            addressLine1={shippingAddress?.address}
            aptAddress={shippingAddress?.address2}
            addressLine2={`${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.postalCode}`}
            phoneNumber={maskedPhoneNumber}
          />
        </Container>
      </LoadingHandler>
    </ShipppingDetailsStaticHeightCard>
  )
}

export default ShippingDetailsCard

import React from 'react'
import Images from '../../../assets/images/index'
import {
  FedexDeviceDetails,
  DeviceSerialNumber,
  Title,
  DeviceImage
} from './styles'
import { CommonProps } from 'src/utils/common-util'

interface DeviceComponentProps {
  commonProps: CommonProps
  corePlan: any
  printerData: any
}

const DeviceComponent: React.FC<DeviceComponentProps> = ({
  commonProps,
  corePlan,
  printerData
}) => {
  const { t } = commonProps
  return (
    <FedexDeviceDetails data-testid="deviceVisible">
      <DeviceImage
        alt={`${corePlan?.title} ${printerData?.printer?.entityDetails?.serialNumber}`}
        src={corePlan?.productimage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = Images.printerPlaceholder
        }}
      />
      <div>
        <Title>{corePlan?.title}</Title>
        <DeviceSerialNumber>
          {t('dashboard.FedExCard.SerialNumber', 'Serial number: ')}
          {''}
          {printerData?.printer?.entityDetails?.serialNumber}
        </DeviceSerialNumber>
      </div>
    </FedexDeviceDetails>
  )
}

export { DeviceComponent }

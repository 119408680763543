import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PRODUCTS } from '../graphql'

import type {
  InkPlanInfo,
  MultipleSubscriptionPlan
} from '../../types/planInfo'
import { CoreOfferPlanInfo } from '../../types/planInfo'
import type {
  ProductBundleItem,
  ProductResult,
  UseGetProductsVariables
} from '../../types/graphql'

const useGetProducts = (variables?: UseGetProductsVariables) => {
  return useQuery(GET_PRODUCTS, { variables })
}

const useLazyGetProducts = () => useLazyQuery<ProductResult>(GET_PRODUCTS)

const findInkPlan = (
  results: ProductResult,
  sku: string
): InkPlanInfo | null => {
  const printerBundle = results?.products?.items[0]
  const inkBundle = {
    options: printerBundle.items.reduce((accumulator, item) => {
      const filtered = item.options.filter(
        (option) => option.product.hp_product_type_label === 'instantInk'
      )
      return accumulator.concat(filtered)
    }, [])
  }

  const inkPlan = inkBundle?.options?.find((plan) => plan.product.sku === sku)

  if (!inkPlan) return null
  return {
    sku: inkPlan.product?.sku,
    pages: inkPlan.product?.hp_pages_per_month ?? 0,
    price: inkPlan.product.price?.regularPrice?.amount?.value ?? 0,
    rolloverPages: inkPlan.product?.hp_max_rollover_pages ?? 0,
    additionalPages: inkPlan.product?.hp_overage_block_size ?? 0,
    additionalPagesPrice: inkPlan.product?.hp_overage_block_price ?? 0
  }
}

const findOptionsInkPlan = (
  optionResults: any,
  sku: string
): InkPlanInfo | null => {
  const filteredArray = optionResults.filter(
    (edit) => edit.product.value.productSku === sku
  )
  const inkPlan = filteredArray.length > 0 ? filteredArray[0] : null

  if (!inkPlan) return null
  return {
    sku: inkPlan.product?.value?.productSku,
    pages: inkPlan.product?.value?.pagesPerMonth ?? 0,
    price: inkPlan.product?.value?.price?.cost ?? 0,
    rolloverPages: inkPlan.product?.value?.maxRolloverPages ?? 0,
    additionalPages: inkPlan.product?.value?.overageBlockSize ?? 0,
    additionalPagesPrice: inkPlan.product?.value?.overageBlockPrice ?? 0
  }
}

const findCoreOfferPlan = (
  results: ProductResult,
  sku: string
): CoreOfferPlanInfo | null => {
  const printerBundle = results?.products?.items[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    sku: coreOffer.product?.sku,
    title: coreOffer.product?.hp_short_config_name,
    productimage: coreOffer.product?.thumbnail.url
  }
}

const findCoreOfferPlanMultipleSubscription = (
  results: ProductResult,
  sku: string,
  subscriptionId: string,
  index: number
): MultipleSubscriptionPlan | null => {
  const printerBundle = results?.products?.items[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    id: index,
    label: coreOffer.product?.hp_short_config_name,
    planId: subscriptionId
  }
}

export {
  useGetProducts,
  useLazyGetProducts,
  findInkPlan,
  findCoreOfferPlan,
  findCoreOfferPlanMultipleSubscription,
  findOptionsInkPlan
}

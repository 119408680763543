import { ProgressBar } from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import { CUSTOM_COLORS } from '../../../__mocks__/constants/styles.constants'
import { CommonProps } from 'src/utils/common-util'
interface RolloverProgressProps {
  printPlanRolloverPageAllowancePrinted: number
  printPlanRolloverPageAllowance: number
  commonProps: CommonProps
}

export const RolloverProgress: React.FC<RolloverProgressProps> = ({
  printPlanRolloverPageAllowancePrinted,
  printPlanRolloverPageAllowance,
  commonProps
}) => {
  const { t } = commonProps

  const progressNameColor = CUSTOM_COLORS.colorGreenEco
  return (
    <ProgressBar
      t={t}
      progressNameColor={progressNameColor}
      color={
        printPlanRolloverPageAllowance <= 0
          ? 'gray1'
          : CUSTOM_COLORS.colorGreenEco
      }
      total={printPlanRolloverPageAllowance}
      current={printPlanRolloverPageAllowancePrinted}
      label={t('usage-data-progress.rollover.label', 'Rollover pages')}
      tip={t(
        'usage-data-progress.rollover.tip',
        'Unused pages roll over to your next monthly allotment.'
      )}
    />
  )
}

import React from 'react'
import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  CardErrorContent,
  ErrorRemorse,
  ErrorRemorseIcon
} from '../EstimatedChargesCard.styles'
import { IconPrinterX } from '@veneer/core/dist/esm/scripts/icons'
import { toCurrency } from 'src/utils/currency'
import { EstimatedChargesHeightCard } from 'src/components/styles'

const PrinterRecivedWithDamage: React.FunctionComponent<any> = ({
  t,
  invoiceAmountData
}) => {
  const getPrinterRecievedWithDamageTexts = () => {
    return [
      <ErrorRemorse key="1">
        <ErrorRemorseIcon>
          <IconPrinterX size={32} color={'gray9'} />
        </ErrorRemorseIcon>
        <CardErrorContent>
          {t(
            'dashboard.paas.estimated-charges.error.cancelledDecs-completeUnsuccessful',
            {
              cancellationFee: toCurrency(invoiceAmountData?.[0]?.totalAmount),
              defaultValue:
                'A final cancellation fee of {{cancellationFee}} will be billed to your account, as the printer was returned with issues or damages.'
            }
          )}
        </CardErrorContent>
      </ErrorRemorse>
    ]
  }
  return (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            texts={getPrinterRecievedWithDamageTexts()}
          />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}

export default PrinterRecivedWithDamage

import {
  HpxHeader,
  LoadingHandler,
  Row,
  MultipleNotification,
  PrinterListOption
} from '@monetization/hpaip-ui-shared-components'
import {
  useFlags,
  PaymentClient,
  SubscriptionStateEnum,
  SubscriptionOptionsResponse,
  Subscription
} from '@monetization/hpaip-notification-rules-react'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import React, { useCallback, useEffect, useState } from 'react'
import CartridgeStatusCard from '../CartridgeStatusCard'
import DeviceDetailsCard from '../DeviceDetailsCard'
import InkPlanDetailsCard from '../InkPlanDetailsCard'
import { MonthlyPlanSummaryCard } from '../MonthlyPlanSummaryCard'
import ShippingDetailsCard from '../ShippingDetailsCard'
import SupportCard from '../SupportCard'
import useShippingAddress from '../../hooks/useShippingAddress'
import {
  Container,
  DashboardGrid,
  StyledPage,
  HeaderSection,
  DropDownStyle,
  ContainerSupportDevice
} from './styles'
import { PaymentBlock } from '../PaymentBlock'
import PaymentMethodCard from '../PaymentMethodCard'
import SubscriptionDetailsCard from '../SubscriptionDetailsCard'
import EstimatedChargesCard from '../EstimatedChargesCard'
import sendOpenTelemetryEvent from 'src/helpers/sendOpenTelemetryEvent'
import useDeviceReturnDetails from 'src/hooks/useDeviceReturnDetails'
import { MemoizedPaperWrapper } from '../PaperWrapper'
import useFetchMultipleSubscription from 'src/hooks/useFetchMultipleSubscription'
import { MultipleSubscriptionPlan } from 'src/types/planInfo'
import { CommonProps } from 'src/utils/common-util'
import { FedexReturnCard } from '../FedexReturnComponent/FedexReturnCard'
import type { InkPlanInfo } from '../../types/planInfo'

interface PageProps {
  commonProps: CommonProps
  setIndex: React.Dispatch<React.SetStateAction<number>>
  index: number
  subscriptionsData: Subscription
  printerProps: any
  refreshPrinterSelection: any
}

export const Page: React.FC<PageProps> = ({
  commonProps,
  setIndex,
  index,
  subscriptionsData,
  printerProps,
  refreshPrinterSelection
}) => {
  const { authProvider, stack, t } = commonProps
  const [refresh, setRefresh] = useState<boolean>(false)
  const [triggerPaperModal, setTriggerPaperModal] = useState<boolean>(false)
  const [onBooks, setOnBooks] = useState<boolean>(true)
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState<any>(' ')
  const [inkPlan, setInkPlan] = useState<InkPlanInfo | null>()

  const flags = useFlags()
  const [printerData, setPrinterData] = useState<any>()
  const [serialNo, setSerialNo] = useState<any>()
  const [corePlan, setCorePlan] = useState<any>()
  const [multipleSubscriptions, setMultipleSubscriptions] = useState<string[]>(
    []
  )
  const [printerOptionDetails, setPrinterOptionDetails] =
    useState<SubscriptionOptionsResponse>(null)

  console.log(serialNo)
  console.log(corePlan)
  console.log(setIndex)
  console.log(index)
  const [isAddedPaperNotification, setIsAddedPaperNotification] =
    useState(false)
  const [updatedNotificationsList, setUpdatedNotificationsList] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          error,
          isLoading,
          printer,
          called,
          root,
          instantInk,
          replacement,
          isRemovePaper,
          subscriptions,
          activePaperSubscription,
          optionsData,
          paperInfo,
          pendingData,
          cancellationInfo
        } = printerProps?.printerData

        if (error) {
          throw error
        }
        setPrinterData({
          error,
          isLoading,
          printer,
          called,
          root,
          instantInk,
          replacement,
          isRemovePaper,
          subscriptions,
          activePaperSubscription,
          optionsData,
          paperInfo,
          pendingData,
          cancellationInfo
        })
      } catch (error) {
        console.error('Error retrieving subscriptions:', error)
      }
    }
    fetchData()
  }, [printerProps?.printerData])

  //code related to multiple subscriptions
  const { productData } = useFetchMultipleSubscription(subscriptionsData)
  useEffect(() => {
    if (productData && subscriptionsData.length > 1) {
      setMultipleSubscriptions(productData)
    }
  }, [productData])

  const returnDetails = useDeviceReturnDetails(
    printerData?.root?.tenantId,
    printerData?.printer?.fulfillment?.value?.fulfillmentOrderId,
    printerData?.printer?.entityDetails?.serialNumber,
    commonProps?.authProvider,
    commonProps?.stack
  )

  const paymentClient = React.useMemo(
    () => new PaymentClient(authProvider, stack),
    [authProvider, stack]
  )

  useEffect(() => {
    const fetchPaymentData = async () => {
      setPaymentLoading(true)
      try {
        const PaymentDetails = await paymentClient.getPaymentDetails(
          printerData?.root?.paymentMethodId
        )
        if (
          PaymentDetails.payment_method_details.payment_type === 'credit_card'
        ) {
          setOnBooks(true)
        } else {
          setOnBooks(false)
        }
        setPaymentData(PaymentDetails)
        setPaymentLoading(false)
      } catch (error) {
        if (error?.response?.status === 404) return setPaymentData(null)
        else {
          sendOpenTelemetryEvent(
            'error in PaymentDetails: ' + JSON.stringify(error)
          )
        }
        setOnBooks(true)
        setPaymentLoading(false)
        console.error('Error:', error)
      }
    }
    if (
      printerData?.printer !== null &&
      printerData?.root?.paymentMethodId &&
      printerData?.called &&
      !printerData.error
    ) {
      fetchPaymentData()
    } else {
      setPaymentData(null)
    }
  }, [paymentClient, printerData])

  const refreshCallback = () => {
    setRefresh(true)
  }

  const resetRefreshCallback = () => {
    setRefresh(false)
  }

  const resetModalPaper = () => {
    setTriggerPaperModal(true)
  }

  const getPrinterData = useCallback(
    (dataFromDeviceDetail) => {
      setSerialNo(printerData?.printer?.entityDetails?.serialNumber)
      setCorePlan(dataFromDeviceDetail)
    },
    [printerData?.printer?.entityDetails?.serialNumber]
  )
  useEffect(() => {
    if (printerData?.optionsData) {
      setPrinterOptionDetails(printerData?.optionsData)
    } else {
      setPrinterOptionDetails(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printerData?.optionsData])

  useEffect(() => {
    if (!isAddedPaperNotification) return
    setUpdatedNotificationsList((prevList) => [
      ...prevList,
      {
        notificationType: 'positive',
        title: t(
          'dashboard.paper-wrapper.inline-notification.title',
          'Your Paper Add-on Service was successfully added'
        ),
        description: t(
          'dashboard.paper-wrapper.inline-notification.description',
          '<p>Congratulations! You will receive an email when your paper is shipped. Your Paper Add-on Service billing will start when you receive your first shipment. It will be prorated to match your HP All-In Plan billing cycle.</p>'
        ),
        notificationID: 'addPaperSuccess',
        showCloseButton: true
      }
    ])
  }, [isAddedPaperNotification, t])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rescindCanel = params.get('rescindCanel')
    if (rescindCanel === 'success') {
      const notificationConfig = {
        notificationType: 'positive',

        title: t(
          'dashboard.rescind-success.title',
          'Your HP All-In Plan has been resumed'
        ),
        description: t(
          'dashboard.rescind-success.description',
          `The details are below. If you experience any issues, please visit <a href='https://www.hp.com/all-in-plan/printer-support' target='_blank'>24/7 Pro live support.</a>`
        ),
        showCloseButton: true,
        notificationID: 'rescindCancel-Success'
      }

      setUpdatedNotificationsList((prevList) => [
        ...prevList,
        notificationConfig
      ])
      const newUrl = window.location.pathname
      if (params.has('rescindCanel')) {
        params.delete('rescindCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
    const rescindPaperCanel = params.get('rescindPaperCanel')
    if (rescindPaperCanel === 'success') {
      const notificationConfig = {
        notificationType: 'positive',

        title: t(
          'dashboard.paper-wrapper.paper-addon-resume.title',
          'Your Paper Add-on Service has been successfully resumed'
        ),
        description: t(
          'dashboard.paper-wrapper.paper-addon-resume.description',
          `The details are below. If you experience any issues, please visit <a href='https://www.hp.com/all-in-plan/printer-support' target='_blank'>24/7 Pro live support.</a>`
        ),
        showCloseButton: true,
        notificationID: 'rescindPaperCanel-resume-Success'
      }

      setUpdatedNotificationsList((prevList) => [
        ...prevList,
        notificationConfig
      ])
      const newUrl = window.location.pathname
      if (params.has('rescindPaperCanel')) {
        params.delete('rescindPaperCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
  }, [printerProps?.notificationsList, t])

  //code related to paper eligibility check
  const addPaperEnabled: boolean =
    flags?.enableAddPaperPostEnrollment &&
    !printerData?.activePaperSubscription &&
    printerProps?.printerData?.root?.state == SubscriptionStateEnum.SUBSCRIBED

  //code related to multiple subscriptions
  const handlePrinterSelect = (index: MultipleSubscriptionPlan) => {
    localStorage.setItem('printerIndex', `${index?.id}`)
    setIndex(index?.id)
    refreshPrinterSelection()
  }
  const multiNotificationArray = [
    ...(printerProps?.notificationsList || []),
    ...updatedNotificationsList
  ]
  const { called, loading, error, shippingAddress } = useShippingAddress(
    printerData?.printer,
    commonProps
  )

  return (
    <StyledPage>
      {
        <MultipleNotification
          multiNotificationArray={multiNotificationArray}
        ></MultipleNotification>
      }
      {multipleSubscriptions.length > 0 && (
        <DropDownStyle>
          <PrinterListOption
            t={t}
            PrinterDetails={productData}
            defaultPrinter={index}
            onPrinterSelect={handlePrinterSelect}
            isOpen={true}
          ></PrinterListOption>
        </DropDownStyle>
      )}
      <HeaderSection>
        <HpxHeader title={t('dashboard.header-title', 'Plan overview')} />
      </HeaderSection>

      <FedexReturnCard
        commonProps={commonProps}
        corePlan={corePlan}
        printerData={printerData}
        returnDetails={returnDetails}
      />

      <Container>
        <LoadingHandler
          loading={
            (printerData?.isLoading || !printerData?.called) &&
            !printerData?.printer
          }
          error={printerData?.error}
          customError={
            <ErrorSection
              description={t(
                'error-section.description',
                'Your account information did not load.'
              )}
            />
          }
        >
          <DashboardGrid>
            {onBooks ? (
              <Row>
                <InkPlanDetailsCard
                  printerData={printerData}
                  printerOptionDetails={printerOptionDetails}
                  deliquencyNotification={'none'}
                  isSubscriptionHavePaper={printerData?.activePaperSubscription}
                  commonProps={commonProps}
                  inkPlan={inkPlan}
                  setInkPlan={setInkPlan}
                  resetModalPaper={resetModalPaper}
                  isAddPaperEnabled={addPaperEnabled}
                />
                <EstimatedChargesCard
                  loadingPrinter={printerData?.isLoading || paymentLoading}
                  printerData={printerData}
                  returnDetails={returnDetails}
                  printerOptionDetails={printerOptionDetails}
                  refresh={refresh}
                  resetRefreshCallback={resetRefreshCallback}
                  commonProps={commonProps}
                />

                <PaymentMethodCard
                  loading={printerData?.isLoading || paymentLoading}
                  error={printerData?.error}
                  called={printerData?.called}
                  PaymentData={paymentData}
                  printer={printerData?.printer}
                  deliquencyNotification={printerProps?.deliquencyStatus}
                  returnDetails={returnDetails?.status}
                  commonProps={commonProps}
                />
              </Row>
            ) : (
              <Row>
                <PaymentBlock printerData={printerData} />
              </Row>
            )}
            <Row>
              <ShippingDetailsCard
                {...printerData}
                deliquencyNotification={printerProps?.deliquencyStatus}
                commonProps={commonProps}
                shippingAddress={shippingAddress}
                called={called}
                loading={loading}
                error={error}
              />

              <SubscriptionDetailsCard
                printerData={printerData}
                returnDetails={returnDetails?.status}
                printerOptionDetails={printerOptionDetails}
                commonProps={commonProps}
              />
            </Row>
            {flags?.enableCartridgeStatusCard ? (
              <Row>
                <CartridgeStatusCard {...printerData} />
                <MonthlyPlanSummaryCard
                  printerData={printerData}
                  refreshCallback={refreshCallback}
                  commonProps={commonProps}
                  returnStatus={returnDetails?.status}
                />
              </Row>
            ) : (
              <Row>
                <MonthlyPlanSummaryCard
                  printerData={printerData}
                  refreshCallback={refreshCallback}
                  commonProps={commonProps}
                  returnStatus={returnDetails?.status}
                />
              </Row>
            )}
            {addPaperEnabled && inkPlan ? (
              <Row>
                <MemoizedPaperWrapper
                  paperInfo={printerData?.paperInfo}
                  printerData={printerData}
                  handleAddedPaperNotification={setIsAddedPaperNotification}
                  commonProps={commonProps}
                  inkPlan={inkPlan}
                  shippingAddress={shippingAddress}
                  triggerPaperModal={triggerPaperModal}
                />
              </Row>
            ) : null}
            <Row>
              <ContainerSupportDevice>
                {/* Prefer to use default styling Cards */}
                <SupportCard commonProps={commonProps} />
                {/* Prefer to use default styling Cards */}
                <DeviceDetailsCard
                  printerData={printerData}
                  deliquencyNotification={printerProps?.deliquencyStatus}
                  onPrinterDataReceived={getPrinterData}
                  commonProps={commonProps}
                />
              </ContainerSupportDevice>
            </Row>
          </DashboardGrid>
        </LoadingHandler>
      </Container>
    </StyledPage>
  )
}

import React from 'react'
import useFulfillmentOrders from '../hooks/useFulfillmentOrders'
import { Row } from '@monetization/hpaip-ui-shared-components'
import SubscriptionDetailsCard from '../components/SubscriptionDetailsCard'
import BillingDetailsCard from '../components/BillingDetailsCard'
import CitizensBalanceCard from '../components/CitizensBalanceCard'

const PaymentBlock: React.FunctionComponent<any> = (
  printerData,
  commonProps
) => {
  const { authProvider, stack } = commonProps
  const hasInvoicedData = useFulfillmentOrders(
    authProvider,
    stack,
    printerData.printer?.fulfillment?.value?.fulfillmentOrderId,
    printerData.printer
  )
  return (
    <Row>
      <SubscriptionDetailsCard {...printerData} />
      <BillingDetailsCard hasInvoicedData={hasInvoicedData} {...printerData} />
      <CitizensBalanceCard hasInvoicedData={hasInvoicedData} {...printerData} />
    </Row>
  )
}

export { PaymentBlock }

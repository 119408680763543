import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace.getTracer('pass-dashboard').startSpan('pass-dashboard', {
    attributes: {
      'workflow.name': 'jarvis/pass-dashboard.error',
      error: true,
      'error.message': message
    }
  })
  span.end()
}

export default sendOpenTelemetryEvent

import styled from 'styled-components'
import { StaticHeightCard } from '../styles'
import { tokens } from '@veneer/tokens'

const { size4, lineHeight4 } = tokens.typography

export const PrintPlanStaticHeightCard = styled(StaticHeightCard)<{
  isSubscriptionHavePaper: boolean
}>`
  & div > section > article {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) =>
      props.isSubscriptionHavePaper ? 'space-between' : 'flex-end'};
  }
  & > div > section > article > article {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 375px) {
    & > div > section > header > h2 {
      font-weight: 400;
      font-size: ${size4};
      line-height: ${lineHeight4};
    }
  }
  @media (min-width: 1008px) {
    & > div > section > header > h2 {
      font-size: ${size4};
      line-height: ${lineHeight4};
    }
  }
`

import React from 'react'
import PaymentNotAvailableCard from './shared/PaymentNotAvailableCard'
import PrinterNotReceivedCard from './shared/PrinterNotReceivedCard'
import PrinterRecivedWithDamage from './shared/PrinterRecivedWithDamage'

const CancelledCard: React.FunctionComponent<any> = ({
  t,
  returnDetails,
  invoiceAmountData
}) => {
  const isCompleteSuccessful = ['completeSuccessful'].includes(
    returnDetails?.status
  )
  const isInvoiceAmountDataEmpty = invoiceAmountData?.length === 0
  const isTimeout = ['timeout', 'completeTimeout'].includes(
    returnDetails?.status
  )
  const isCompleteUnsuccessful = ['completeUnsuccessful'].includes(
    returnDetails?.status
  )

  const renderContent = () => {
    if (isCompleteSuccessful || isInvoiceAmountDataEmpty) {
      return <PaymentNotAvailableCard t={t} />
    } else if (isTimeout) {
      return (
        <PrinterNotReceivedCard
          t={t}
          returnDetails={returnDetails}
          invoiceAmountData={invoiceAmountData}
        />
      )
    } else if (isCompleteUnsuccessful) {
      return (
        <PrinterRecivedWithDamage t={t} invoiceAmountData={invoiceAmountData} />
      )
    } else {
      return <PaymentNotAvailableCard t={t} />
    }
  }

  return renderContent()
}

export default CancelledCard

import React from 'react'
import {
  QRImage,
  QrContent,
  QrCard,
  QRCodeStyle,
  TextBoxStyle,
  TextOrderNumber,
  TextTitle
} from './styles'
import { CommonProps } from 'src/utils/common-util'
import { ReturnData } from 'src/types/return'
interface QrCardComponentProps {
  commonProps: CommonProps
  returnDetails: ReturnData
}
const QrCardComponent: React.FC<QrCardComponentProps> = ({
  commonProps,
  returnDetails
}) => {
  const { t } = commonProps
  return (
    <QrCard data-testid="qrcardVisible">
      <QRImage>
        <QRCodeStyle
          value={`FDX1RT${returnDetails?.returnNumber}`}
          viewBox={`0 0 256 256`}
        />
        <TextBoxStyle>
          <TextTitle>
            {t('dashboard.FedExCard.rmaNumber', 'RMA Number:')}
          </TextTitle>
          <TextOrderNumber>{`FDX1RT${returnDetails?.returnNumber}`}</TextOrderNumber>
        </TextBoxStyle>
      </QRImage>
      <QrContent>
        {t(
          'dashboard.FedExCard.content2',
          'If you still have your original packaging, please use it for your return; otherwise, FedEx will provide it at no charge.'
        )}
      </QrContent>
    </QrCard>
  )
}

export { QrCardComponent }

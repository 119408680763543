import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import {
  CardErrorContent,
  ErrorRemorse,
  ErrorRemorseIcon
} from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'
import IconPrinterWarning from '@veneer/core/dist/esm/scripts/icons/icon_printer_warning'

const ItemReceivedCard: React.FunctionComponent<any> = ({ t }) => {
  const getEstimatedChargesTexts = () => {
    return [
      <ErrorRemorse key="2">
        <ErrorRemorseIcon>
          <IconPrinterWarning size={32} color={'gray9'} />
        </ErrorRemorseIcon>
        <CardErrorContent>
          {t(
            'dashboard.paas.estimated-charges.cancellingDescOne',
            'Thank you for returning your printer. We are currently inspecting it for any damage.'
          )}
        </CardErrorContent>
      </ErrorRemorse>
    ]
  }
  return (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError forceHeight={false} texts={getEstimatedChargesTexts()} />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}
export default ItemReceivedCard

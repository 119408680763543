import CitizensPayIcon from './citizens-pay.svg'
import PreArrivalCitizenPayIcon from './Prearival-Citizen-Pay.png'
import PaymentMethodIcon from './payment-method.svg'
import visa from './visa.svg'
import amex from './amex.svg'
import discover from './discover.svg'
import masterCard from './master_card.svg'
import printerPlaceholder from './printer-placeholder.png'
import pcPlaceholder from './pc-placeholder.png'

const Images = {
  CitizensPayIcon,
  PreArrivalCitizenPayIcon,
  PaymentMethodIcon,
  visa,
  masterCard,
  discover,
  amex,
  printerPlaceholder,
  pcPlaceholder
}

export default Images

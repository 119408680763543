import React, { useMemo } from 'react'

import {
  CardError,
  LoadingHandler,
  SubscriptionDetails
} from '@monetization/hpaip-ui-shared-components'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'

import IconDocument from '@veneer/core/dist/esm/scripts/icons/icon_document'
import { SubscriptionDetailsStaticHeightCard } from './styles'
import { CardLink, CardLinkWrapper, ChevronRightIcon } from '../styles'
import moment from 'moment'

const SubscriptionDetailsCard: React.FunctionComponent<any> = ({
  printerData,
  returnDetails,
  commonProps
}) => {
  const { t } = commonProps

  const isLoading =
    printerData.isLoading ||
    (!printerData.called && !printerData.isLoading) ||
    !printerData.called

  const cancellationInfo = printerData?.cancellationInfo

  const { hideFooter } = useMemo(() => {
    const hideLink = [SubscriptionStateEnum.CANCELED]
    return {
      hideFooter:
        hideLink.includes(printerData?.printer?.state) ||
        returnDetails === 'completeunSuccessful' ||
        returnDetails === 'timeout' ||
        returnDetails === 'completetimeout'
    }
  }, [printerData?.printer?.state, returnDetails])

  return (
    <SubscriptionDetailsStaticHeightCard
      icon={<IconDocument color={'gray9'} />}
      title={t('subscription-details.title', 'Plan information')}
      footer={
        !hideFooter && (
          <CardLink
            commonProps={commonProps}
            path={`/ucde/hp-all-in-print-plan/cancellation/${printerData?.root?.subscriptionId}`}
            disabled={cancellationInfo?.buttonDisable}
            text={
              <>
                {cancellationInfo?.showCancellationStatus && (
                  <>
                    {t(
                      'devices-section-header.cancellation-status-link',
                      'Cancellation status'
                    )}
                    <ChevronRightIcon
                      disabled={cancellationInfo?.buttonDisable}
                    />
                  </>
                )}
                {cancellationInfo?.showCancelPlan && (
                  <CardLinkWrapper id="cancel-plan-button">
                    {t(
                      'devices-section-header.cancellation-plan-link',
                      'Cancel Plan'
                    )}
                    <ChevronRightIcon
                      disabled={cancellationInfo?.buttonDisable}
                    />
                  </CardLinkWrapper>
                )}
              </>
            }
          />
        )
      }
      autoHeight="true"
    >
      <LoadingHandler
        loading={isLoading}
        error={printerData.error}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<IconDocument size={32} />}
            texts={[
              t('subscription-details.error.title', 'Something went wrong'),
              t(
                'subscription-details.error.description',
                'Your plan information did not load.'
              )
            ]}
          />
        }
      >
        <SubscriptionDetails
          t={t}
          subscriptionId={printerData.root?.friendlySubscriptionId}
          subscriptionStartDate={moment(
            printerData.printer?.entityStartDate?.split('T')[0]
          ).format('MMM DD, YYYY')}
        />
      </LoadingHandler>
    </SubscriptionDetailsStaticHeightCard>
  )
}

export default SubscriptionDetailsCard

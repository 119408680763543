import React, { FC, useEffect, useState } from 'react'
import IconCalendarMonth from '@veneer/core/dist/esm/scripts/icons/icon_calendar_month'
import IconReload from '@veneer/core/dist/esm/scripts/icons/icon_reload'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import {
  HeaderBlock,
  LoaderBlock,
  SyncBlock,
  TitleBlock,
  TitleContent
} from '../styles'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import { usePlanUsageRefresh } from '../../../hooks/usePlanUsageRefresh'
import SyncMessages from './SyncMessages'
import { CommonProps } from 'src/utils/common-util'

interface MonthlyPlanHeaderProps {
  lastUpdatedAt: string
  printerData: any
  triggerFetchCallback: () => void
  commonProps: CommonProps
}

const MonthlyPlanHeader: FC<MonthlyPlanHeaderProps> = (
  props: MonthlyPlanHeaderProps
) => {
  const { t } = props.commonProps
  const { lastUpdatedAt, printerData, triggerFetchCallback } = props
  const [getPlanUsage, { data, loading, error }] = usePlanUsageRefresh(
    props.commonProps
  )
  const [reload, setReload] = useState<boolean>(false)

  const fetchMonthlySummaryData = async () => {
    const subscriptionId = printerData?.root?.subscriptionId
    const entityId = printerData?.instantInk?.entityId

    try {
      await getPlanUsage(subscriptionId, entityId)
    } catch (error) {
      setReload(false)
    }
  }

  const handleReload = async () => {
    setReload(true)
    await fetchMonthlySummaryData()
  }

  useEffect(() => {
    // trigger after 20s
    if (data && !loading) {
      setTimeout(() => {
        triggerFetchCallback()
        setTimeout(() => {
          setReload(false)
        }, 2000)
      }, 20000)
    }
    if (error) {
      setReload(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])
  useEffect(() => {
    if (printerData?.printer?.state === SubscriptionStateEnum.SUBSCRIBED) {
      handleReload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderBlock>
      <TitleBlock>
        <IconCalendarMonth size={32} color={'gray9'} />
        <TitleContent>
          {t(
            'dashboard.paas.monthly-plan-summary.title',
            'Monthly plan summary'
          )}
        </TitleContent>
      </TitleBlock>
      <SyncBlock
        style={{
          alignItems: lastUpdatedAt && !error ? 'center' : 'flex-start'
        }}
      >
        <SyncMessages
          reload={reload}
          error={error}
          lastUpdatedAt={lastUpdatedAt}
          commonProps={props?.commonProps}
        />
        <LoaderBlock style={{ cursor: reload ? 'not-allowed' : 'pointer' }}>
          {reload ? (
            <LoadingHandler loading />
          ) : (
            <IconReload
              size={24}
              data-testid="reload-button"
              color="hpBlue7"
              onClick={handleReload}
            />
          )}
        </LoaderBlock>
      </SyncBlock>
    </HeaderBlock>
  )
}

export default MonthlyPlanHeader

import { useMemo } from 'react'
import moment from 'moment'
import {
  SubscriptionStateEnum,
  SubscriptionEntity,
  SubscriptionEntityTypeEnum
} from '@monetization/hpaip-notification-rules-react'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { LocalizationType } from 'src/types/localization'
import { NavigationType } from 'src/types/shell'
export const isInRemorsePeriod = (validUntil, currentDate) => {
  return moment(currentDate).isBefore(moment(validUntil))
}

const disableCardStateUtil = [
  SubscriptionStateEnum.CANCELED,
  SubscriptionStateEnum.CANCELING,
  SubscriptionStateEnum.PENDING
]
const disableCardLinksUtil = [
  SubscriptionStateEnum.CANCELED,
  SubscriptionStateEnum.CANCELING,
  SubscriptionStateEnum.PENDING
]
export enum DeliquencyNotificationTypes {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRED = 'about_to_expire',
  NONE = 'none'
}

export const useCardState = (subscriptionIsLoading, printer, isReplacement) => {
  const { disableCard, disableCardLink } = useMemo(() => {
    const disableCardState = disableCardStateUtil
    const disableCardLinks = disableCardLinksUtil
    // If it's a replacement, no need to disable the card or link
    if (isReplacement) {
      return { disableCard: false, disableCardLink: false }
    }
    if (subscriptionIsLoading || !printer) {
      return { disableCard: false, disableCardLink: false }
    }

    return {
      disableCard: disableCardState.includes(printer.state),
      disableCardLink: disableCardLinks.includes(printer.state)
    }
  }, [subscriptionIsLoading, printer, isReplacement])

  return { disableCard, disableCardLink }
}

export const isDeliquency = (state) => {
  if (
    state === DeliquencyNotificationTypes.INACTIVE ||
    state === DeliquencyNotificationTypes.SUSPENDED
  ) {
    return true
  }
  return false
}
export const isDeliquencyStateOverdue = (state) => {
  if (
    state === DeliquencyNotificationTypes.OVERDUE ||
    state === DeliquencyNotificationTypes.ABOUT_TO_EXPIRED
  ) {
    return true
  }
  return false
}

export interface CommonProps {
  authProvider: JarvisAuthProvider
  stack: Stack
  t: any
  analytics: any
  localization: LocalizationType
  navigation: NavigationType
  bizlogicId: string
}

export const getMainPC = async (
  entities: SubscriptionEntity[]
): Promise<SubscriptionEntity | any> => {
  const allPC = entities.filter(
    (entity) => entity.entityType === SubscriptionEntityTypeEnum.PC
  )

  if (allPC.length === 1) {
    return { pcData: allPC[0], replacement: false }
  } else if (allPC.length === 0) {
    return { pcData: null, replacement: false }
  }

  const replacedByEntities = allPC.filter(
    (entity) =>
      entity.linkedEntities &&
      entity.linkedEntities?.some(
        (linkedEntity) =>
          linkedEntity?.type === 'replacedBy' &&
          !entity.linkedEntities?.some(
            (otherLinkedEntity) => otherLinkedEntity?.type === 'replacedWith'
          )
      )
  )

  if (replacedByEntities.length > 0) {
    return { pcData: replacedByEntities[0], replacement: true }
  }

  const pendingReturnPrinters = allPC
    .filter((pcData) => pcData.state === 'inactive')
    .sort((a, b) => {
      return (
        new Date(b.entityStartDate).getTime() -
        new Date(a.entityStartDate).getTime()
      )
    })
  if (pendingReturnPrinters[0]) {
    return { pcData: pendingReturnPrinters[0], replacement: true }
  }
  return null
}

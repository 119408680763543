import {
  Address,
  useLazyFetchRequest,
  AddressClient
} from '@monetization/hpaip-notification-rules-react'
import { useEffect, useMemo } from 'react'

const useShippingAddress = (printer, commonProps) => {
  const { authProvider, stack } = commonProps
  const addressClient = useMemo(
    () => new AddressClient(authProvider, stack),
    [authProvider, stack]
  )
  const [fetchAddress, { loading, error, data: shippingAddress, called }] =
    useLazyFetchRequest<Address>(() => {
      return addressClient.getAddressById(printer?.shippingAddressId)
    })

  useEffect(() => {
    if (printer?.shippingAddressId && !called) {
      fetchAddress()
    }
  }, [called, fetchAddress, printer])

  return { called, loading, error, shippingAddress }
}

export default useShippingAddress

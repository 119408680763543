import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import { StaticHeightCard } from '../styles'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
import { CUSTOM_COLORS } from '../../__mocks__/constants/styles.constants'

const { size4: layoutSize4 } = tokens.layout
const { gray12 } = tokens.color
export const SubscriptionDetailsStaticHeightCard = styled(StaticHeightCard)`
  & section > header {
    margin-bottom: ${layoutSize4};
  }
  & section > header > h2 {
    color: ${gray12};
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  & section > article > div > dl > dd > div > svg {
    margin-top: 3px;
  }
  & section > article > div > dl > dd > div > span {
    margin-top: 2px;
  }
`
export const ChevronRightIcon = styled(IconChevronRight)`
  color: #0278ab !important;
`
export const CardLabel = styled.span`
  color: ${CUSTOM_COLORS.colorDeepBlue};
`

import React, { FC } from 'react'
import { PagesBlock } from '../styles'
import { CommonProps } from 'src/utils/common-util'

interface MonthlyPlanPagesProps {
  totalPages: number
  commonProps: CommonProps
}
const MonthlyPlanPages: FC<MonthlyPlanPagesProps> = (
  props: MonthlyPlanPagesProps
) => {
  const { t } = props?.commonProps

  const { totalPages } = props
  return (
    <PagesBlock>
      <p>
        {t(
          'paas.monthly-plan-summary.total-pages-printed',
          'Total pages printed'
        )}
      </p>
      <p>{totalPages}</p>
    </PagesBlock>
  )
}

export default MonthlyPlanPages

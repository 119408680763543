import React from 'react'
import ReturnCard from './shared/ReturnCard'
import ItemReceivedCard from './shared/ItemReceivedCard'
import ChargesCard from './shared/ChargesCard'
import PaymentNotAvailableCard from './shared/PaymentNotAvailableCard'
import ApiErrorCard from '../EstimatedChargesCard/shared/APIErrorCard'
const CancellingCard: React.FunctionComponent<any> = ({
  t,
  isCancelledInRemorse,
  costValue,
  returnDetails,
  estimatedChargeData,
  error,
  disableCardLink
}) => {
  let renderComponent

  if (isCancelledInRemorse) {
    if (['new', 'processing', 'initiated'].includes(returnDetails?.status)) {
      renderComponent = (
        <ReturnCard returnDetails={returnDetails} t={t} costValue={costValue} />
      )
    } else if (['itemReceived'].includes(returnDetails?.status)) {
      renderComponent = <ItemReceivedCard t={t} />
    } else {
      renderComponent = <ApiErrorCard t={t} />
    }
  } else if (returnDetails?.status === null) {
    renderComponent = (
      <ChargesCard
        estimatedChargeData={estimatedChargeData}
        t={t}
        error={error}
        disableCardLink={disableCardLink}
      />
    )
  } else {
    renderComponent = <PaymentNotAvailableCard t={t} />
  }

  return renderComponent
}
export default CancellingCard

import React, { FC } from 'react'

import { MessageBlock } from '../styles'
import { getFormattedDateSync } from 'src/helpers/dateTime'
import { CommonProps } from 'src/utils/common-util'

interface SyncMessagesProps {
  reload: boolean
  error: Error
  lastUpdatedAt: string
  commonProps: CommonProps
}

const SyncMessages: FC<SyncMessagesProps> = (props: SyncMessagesProps) => {
  const { reload, error, lastUpdatedAt } = props
  const { t } = props?.commonProps

  const getLastUpdatedAt = () => {
    return (
      <>
        {`${t(
          'dashboard.paas.monthly-plan-summary.messages.synced',
          'Synced'
        )} ${getFormattedDateSync(lastUpdatedAt)}`}
      </>
    )
  }

  const renderMessage = () => {
    switch (true) {
      case reload:
        return (
          <>
            {t(
              'dashboard.paas.monthly-plan-summary.messages.initial',
              'Contacting printer. \n This may take a minute.'
            )}
          </>
        )
      case Boolean(error):
        return (
          <>
            <>
              {t(
                'dashboard.paas.monthly-plan-summary.messages.error',
                'Unable to connect to the printer.\n Check your connection and try again later'
              )}
            </>
            <p>{lastUpdatedAt && getLastUpdatedAt()}</p>
          </>
        )
      case Boolean(lastUpdatedAt):
        return <> {getLastUpdatedAt()}</>
      default:
        return (
          <>
            {t(
              'dashboard.paas.monthly-plan-summary.messages.delayed',
              'It can take several minutes for the printer \n to refresh with data from the cloud.'
            )}
          </>
        )
    }
  }
  return <MessageBlock>{renderMessage()}</MessageBlock>
}

export default SyncMessages

import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_BILLING_DETAILS } from '../graphql'

import type {
  UseGetBillingDetailsVariables,
  BillingDetailsResult
} from '../../types/graphql'

const useGetBillingDetails = (variables: UseGetBillingDetailsVariables) => {
  return useQuery(GET_BILLING_DETAILS, { variables })
}

const useLazyGetBillingDetails = () =>
  useLazyQuery<BillingDetailsResult>(GET_BILLING_DETAILS)

export { useGetBillingDetails, useLazyGetBillingDetails }

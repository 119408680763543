import { useState, useEffect, useCallback } from 'react'
import {
  useLazyGetProducts,
  findCoreOfferPlanMultipleSubscription
} from '../../src/api/services'

export interface PrinterData {
  id: string
  label: string
  planId: string
}

interface UseFetchMultipleSkusResult {
  productData: any[]
  loading: boolean
  error: any
}

const useFetchMultipleSubscription = (
  multipleSubscriptions: any
): UseFetchMultipleSkusResult => {
  const [productData, setProductData] = useState<any[]>([])
  const [getProducts] = useLazyGetProducts()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const processSkus = useCallback(
    async (
      parentSkus: string[],
      productSkus: string[],
      multipleSubId: string[]
    ) => {
      setLoading(true)

      for (let index = 0; index < parentSkus.length; index++) {
        const item = parentSkus[index]

        try {
          await new Promise((resolve, reject) => {
            getProducts({
              variables: { sku: item },
              onCompleted: (data) => {
                const coreOfferPlan = findCoreOfferPlanMultipleSubscription(
                  data,
                  productSkus[index],
                  multipleSubId[index],
                  index
                )
                setProductData((prevData) => [...prevData, coreOfferPlan])
                resolve(true)
                setLoading(false)
              },
              onError: (error) => {
                console.error('Error fetching product:', error)
                reject(error)
                setLoading(false)
                setError(true)
              }
            })
          })
        } catch (error) {
          console.error(
            `Error processing SKU ${item} at index ${index}:`,
            error
          )
        }
      }
    },
    [getProducts]
  )

  useEffect(() => {
    const fetchMultipleSku = async () => {
      const { productSkus, parentSkus, multipleSubId } = getProductParentSku(
        multipleSubscriptions
      )
      await processSkus(parentSkus, productSkus, multipleSubId)
    }

    fetchMultipleSku()
  }, [multipleSubscriptions, processSkus])

  const getProductParentSku = (subscriptionData) => {
    const productSkus: string[] = []
    const parentSkus: string[] = []
    const multipleSubId: string[] = []

    subscriptionData?.forEach((subscription: any) => {
      subscription?.entities?.forEach((entity: any) => {
        if (entity?.entityType === 'iot-printer') {
          productSkus.push(entity.product.value.productSku)
          parentSkus.push(entity.product.value.parentProductSku)
          multipleSubId.push(subscription.subscriptionId)
        }
      })
    })

    return { productSkus, parentSkus, multipleSubId }
  }

  return { productData, loading, error }
}

export default useFetchMultipleSubscription

import {
  BillingDetails,
  BillingDetailsFooter,
  CardError,
  dateToUTC,
  formatDate,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  formatCurrency,
  isPcEntityType,
  SubscriptionEntity,
  SubscriptionEntityTypeEnum,
  CommerceBillingCycleClient
} from '@monetization/hpaip-notification-rules-react'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React, { useEffect, useMemo, useState } from 'react'
import { useLazyGetBillingDetails } from '../../api/services'
import { BillingStaticHeightCard } from '../styles'
import {
  CardErrorContent,
  StyledIconCalendarDay
} from './BillingDetailsCard.styles'
import sendOpenTelemetryEvent from 'src/helpers/sendOpenTelemetryEvent'

const identifyHybrid = (entities?: SubscriptionEntity[]): boolean => {
  const printerEntity = entities?.some(
    (entity) => entity.entityType === SubscriptionEntityTypeEnum.PRINTER
  )
  const pcEntity = entities?.some((entity) => isPcEntityType(entity.entityType))

  return printerEntity && pcEntity
}

const BillingDetailsCard: React.FunctionComponent<any> = ({
  commonProps,
  hasInvoicedData,
  printerData
}) => {
  const { authProvider, stack, t, localization } = commonProps
  const [isPreArrivalState, setPreArrvivalState] = useState<boolean>(false)
  const [paymentDueDateDetails, setPaymentDueDate] = useState<any>('')

  const orderId = printerData.printer?.source?.value?.orderId

  const billingDueDate = useMemo(() => {
    return new CommerceBillingCycleClient(authProvider, stack)
  }, [authProvider, stack])

  /* istanbul ignore next */
  async function fetchMyAPI() {
    try {
      const billingDueDateDetails = await billingDueDate.getBillingDetails(
        printerData.printer?.commerce?.value?.subscriptionId
      )
      /* istanbul ignore next */
      setPaymentDueDate(billingDueDateDetails?.billingDate)
    } catch (error) {
      if (error?.response?.status === 404) return setPaymentDueDate(null)
      else {
        sendOpenTelemetryEvent(
          'error in billingDueDateDetails: ' + JSON.stringify(error)
        )
      }
      console.log('something went wrong')
    }
  }
  fetchMyAPI()
  const [getBillingDetails, { data, loading, error, called }] =
    useLazyGetBillingDetails()

  const IsSettled = error?.message?.includes('no setteled')

  useEffect(() => {
    setPreArrvivalState(!hasInvoicedData)
  }, [authProvider, printerData.printer, hasInvoicedData])

  useEffect(() => {
    if (IsSettled) setPreArrvivalState(IsSettled)
  }, [IsSettled])

  useEffect(() => {
    if (!orderId && !called) return

    getBillingDetails({
      variables: { orderId }
    })
  }, [called, getBillingDetails, orderId])

  const isHybrid = useMemo(
    () => identifyHybrid(printerData.root?.entities),
    [printerData.root]
  )

  const isLoading =
    printerData.isLoading || loading || (!called && !loading) || !called
  const isPaymentDueThisMonthValid =
    !isLoading &&
    typeof data?.getCitizensBillingDetails?.PaymentDueThisMonth === 'number'

  // const isOnErrorState =
  //   !isLoading &&
  //   (!!error ||
  //     !isPaymentDueDatePresent ||
  //     (!isPaymentDueThisMonthValid && !isPreArrivalState) ||
  //     isPreArrivalState)
  const isOnErrorState = !isLoading && (!!error || isPreArrivalState)

  const lastUpdatedDate = new Date()

  const paymentDueDate = paymentDueDateDetails
    ? formatDate(localization.locale, dateToUTC(paymentDueDateDetails))
    : ''

  const txtStates = {
    error: [
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.minimum-payment-card.error.title.paragraph-1',
            'Something went wrong.'
          )}
        </CardErrorContent>
        <CardErrorContent>
          {t(
            'dashboard.paas.minimum-payment-card.error.title.paragraph-2',
            'Your payment information did not load.'
          )}
        </CardErrorContent>
      </>,
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.minimum-payment-card.error.description',
            'Please refresh the page.'
          )}
        </CardErrorContent>
      </>
    ],
    isPreArrivalState: [
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.minimum-payment-card.pre-arrival',
            'Minimum payment amount will display here soon'
          )}
        </CardErrorContent>
      </>
    ]
  }

  return (
    <BillingStaticHeightCard
      icon={<IconCalendarDay size={24} color={'gray9'} />}
      title={t('billing-cycle.title', 'Minimum payment due')}
      footer={
        <BillingDetailsFooter
          t={t}
          isHybrid={isHybrid}
          totalValue={
            isPaymentDueThisMonthValid
              ? formatCurrency(
                  data.getCitizensBillingDetails.PaymentDueThisMonth,
                  localization.locale
                )
              : null
          }
        />
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={isOnErrorState}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<StyledIconCalendarDay size={32} color="gray9" />}
            texts={
              isPreArrivalState ? txtStates.isPreArrivalState : txtStates.error
            }
          />
        }
      >
        <BillingDetails
          t={t}
          isHybrid={isHybrid}
          paymentDueDate={paymentDueDate}
          lastUpdatedDate={formatDate(localization.locale, lastUpdatedDate)}
        />
      </LoadingHandler>
    </BillingStaticHeightCard>
  )
}

export default BillingDetailsCard

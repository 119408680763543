import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import { StaticHeightCard } from '../styles'

const { gray6, gray9 } = tokens.color
const {
  family0,
  size0,
  lineHeight1,
  size2,
  lineHeight3,
  lineHeight2,
  size4,
  lineHeight4
} = tokens.typography

const { size5, size4: layoutSize4 } = tokens.layout

export const FlexSpace = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
`

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`
export const CardlabelSmall = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size0};
  line-height: ${lineHeight1};
  display: inline-flex;
  gap: 4px;
  letter-spacing: 0.04em;
  color: ${gray6};
`
export const CardContentWraper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14vh;
`

export const Cardlabel = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size0};
  line-height: ${lineHeight1};
  color: ${gray6};
  align-items: center;
  display: flex;
  gap: 4px;
`
export const CardlabelText = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size2};
  line-height: ${lineHeight2};
  color: ${gray9};
`

export const CardText = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray9};
`
export const CardContent = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size0};
  line-height: ${lineHeight1};
  color: ${gray9};
`
export const CardContentSmall = styled.div`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size0};
  line-height: ${lineHeight1};
  gap: 5px;
  letter-spacing: 0.04em;
`
export const CardTitle = styled.p`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size4};
  line-height: ${lineHeight4};
  color: ${gray9};
  margin-top: 4px;
  margin-bottom: 4px;
`
export const Border = styled.div`
  border-top: 1px solid #dbdbdb;
  margin-top: 8px;
  margin-bottom: 8px;
`
export const CardErrorContent = styled.p`
  color: ${gray9};
  font-family: ${family0};
`
export const CardErrorContentCancel = styled.p`
  color: ${gray9};
  font-family: ${family0};
`

export const CardErrorDescription = styled.p`
  color: ${gray9};
  font-family: ${family0};
  margin-top: ${size5};
`
export const CancellingErrorStaticHeightCard = styled(StaticHeightCard)`
  & section > header {
    margin-bottom: ${layoutSize4};
  }

  & section > article > div > dl > dd > div > svg {
    margin-top: 3px;
  }
  & section > article > div > dl > dd > div > span {
    margin-top: 2px;
  }
`

export const ErrorDesc = styled.div`
  color: ${gray6};
  font-family: ${family0};
  line-height: ${lineHeight1};
  font-size: ${size0};
`
export const ErrorRemorseIcon = styled.div``
export const ErrorRemorse = styled.div`
  // background-color: red;
  // border-color: transparent;
  // border-radius: 16px;
  // border-style: solid;
  // border-width: 1px;
  // box-sizing: inherit;
  // color: #212121;
`
export const CardErrorTitle = styled.p`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size4};
  line-height: ${lineHeight4};
  color: ${gray9};
  margin-top: 4px;
`
export const EstimatedErrorCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 108px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

import React from 'react'
import { Link } from '@monetization/hpaip-ui-shared-components'
import { CommonProps } from 'src/utils/common-util'

export interface InternalLinkProps {
  text: string | JSX.Element
  path: string
  className?: string
  onClick?: () => void
  disabled?: boolean
  isDisabled?: boolean
  commonProps: CommonProps
}

export const InternalLink: React.FunctionComponent<InternalLinkProps> = ({
  text,
  path,
  onClick,
  className,
  disabled = false,
  isDisabled = true,
  commonProps
}) => {
  const { navigation } = commonProps
  const hasUcdeInPath = window.location.pathname.includes('/ucde')
  const finalPath = hasUcdeInPath ? path : path.replace('/ucde/', '')

  const handleOnClick = (event) => {
    onClick?.()
    event.preventDefault()
    navigation.push(finalPath)
  }

  return (
    <Link
      as="button"
      className={className}
      href={window.location.origin + finalPath}
      onClick={handleOnClick}
      disabled={disabled}
      isDisabled={isDisabled}
    >
      {text}
    </Link>
  )
}

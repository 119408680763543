import {
  CardError,
  InkPlanDetails,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  navigationLinksHpAllIn,
  SubscriptionStateEnum,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import IconPrinterInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_printer_ink_drop'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  findInkPlan,
  findOptionsInkPlan,
  useLazyGetProducts
} from '../../api/services'
import { CardLink, ChevronRightIcon, CardLinkWrapper } from '../styles'

import { StyledIconInkDoubleDrops } from './InkPlanDetailsCard.styles'
import { PrintPlanStaticHeightCard } from './styles'
import { isDeliquency } from '../../utils/common-util'

const InkPlanDetailsCard: React.FunctionComponent<any> = ({
  printerData,
  printerOptionDetails,
  deliquencyNotification,
  isSubscriptionHavePaper,
  commonProps,
  inkPlan,
  setInkPlan,
  resetModalPaper,
  isAddPaperEnabled
}) => {
  const { t, navigation } = commonProps

  const isActive = isDeliquency(deliquencyNotification)
  const flags = useFlags()
  const [getProducts, getProductsQuery] = useLazyGetProducts()
  const [error, setError] = useState<boolean>(false)
  const [hasChecked, setHasChecked] = useState(false)
  const [addRemovePaper, setaddRemovePaper] = useState<string>()

  const { disableCard, disableCardLink } = useMemo(() => {
    const disableCardState = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING
    ]
    const disableCardLinks = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING,
      SubscriptionStateEnum.PENDING
    ]

    if (printerData?.isLoading || !printerData?.printer)
      return { disableCard: true, disableCardLink: true }
    return {
      disableCard: disableCardState.includes(printerData?.printer.state),
      disableCardLink: disableCardLinks.includes(printerData?.printer.state)
    }
  }, [printerData?.isLoading, printerData?.printer])

  useEffect(() => {
    const getPlanDetails = async () => {
      if (printerData?.isLoading || !printerData?.instantInk) return
      const values = printerData?.instantInk?.product?.value
      const { parentProductSku, productSku } = values || {}
      if (flags?.enableFreezePricing) {
        const optionDetails = printerOptionDetails?.entities[0]?.edit
        setInkPlan(findOptionsInkPlan(optionDetails, productSku))
      } else {
        if (!parentProductSku || !productSku) return

        getProducts({
          variables: { sku: parentProductSku },
          onCompleted: (data) => setInkPlan(findInkPlan(data, productSku))
        })
      }
    }
    if (printerOptionDetails) {
      setError(false)
      getPlanDetails()
    } else if (hasChecked) {
      setError(true)
    } else {
      setHasChecked(true)
    }
  }, [getProducts, printerData, printerOptionDetails])

  const computeLoadingStatus = () => {
    if (flags?.enableFreezePricing && inkPlan) {
      return printerData?.isLoading
    } else {
      return (
        printerData?.isLoading ||
        error ||
        getProductsQuery?.loading ||
        (!getProductsQuery.called && !getProductsQuery.loading) ||
        !getProductsQuery.called
      )
    }
  }

  const computeErrorStatus = () => {
    if (flags?.enableFreezePricing && inkPlan) {
      return printerData?.error || (!inkPlan && !computeLoadingStatus())
    } else {
      return (
        printerData?.error ||
        getProductsQuery.error ||
        (!inkPlan && !isLoading) ||
        error
      )
    }
  }
  const isLoading = computeLoadingStatus()
  const isError = computeErrorStatus()

  const shouldShowFooter = !isLoading && !isError

  /* istanbul ignore next */
  const handleaddpaperService = useCallback(() => {
    resetModalPaper()
  }, [resetModalPaper])

  /* istanbul ignore next */
  const handleremovepaperService = async () => {
    const path = `/ucde/hp-all-in-print-plan/cancellation/${printerData?.root?.subscriptionId}?entity=paper`
    const hasUcdeInPath = window.location.pathname.includes('/ucde')
    const finalPath = hasUcdeInPath ? path : path.replace('/ucde/', '')
    navigation.push(finalPath)
  }
  useEffect(() => {
    async function fetchData() {
      if (
        flags?.enableManagePaperPostEnrollment &&
        printerData?.isRemovePaper
      ) {
        setaddRemovePaper('removePaperService')
      } else if (isAddPaperEnabled) {
        setaddRemovePaper('addPaperService')
      }
    }
    if (printerData) fetchData()
  }, [printerData])

  return (
    <PrintPlanStaticHeightCard
      isSubscriptionHavePaper={isSubscriptionHavePaper}
      icon={<IconPrinterInkDrop color={'gray12'} size={32} />}
      title={t('dashboard.paas.ink-plan-details.title', 'My plan')}
      contentPosition="bottom"
      autoHeight="true"
      $disabled={isLoading ? false : (disableCard && !isError) || isActive}
      footer={
        shouldShowFooter && (
          <CardLink
            path={navigationLinksHpAllIn.plans}
            disabled={disableCardLink}
            isDisabled={isActive}
            text={
              <CardLinkWrapper id="change-plan-button">
                {t(
                  'dashboard.paas.ink-plan-details.change-plan',
                  'Change plan'
                )}

                <ChevronRightIcon disabled={disableCardLink} />
              </CardLinkWrapper>
            }
            commonProps={commonProps}
          />
        )
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={Boolean(isError)}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<StyledIconInkDoubleDrops size={32} />}
            texts={[
              <>
                <p>
                  {t(
                    'dashboard.paas.ink-plan-details.error.title.paragraph-1',
                    'Something went wrong.'
                  )}
                </p>
                <p>
                  {t(
                    'dashboard.paas.ink-plan-details.error.title.paragraph-2',
                    'Your print plan information did not load.'
                  )}
                </p>
              </>,

              t(
                'dashboard.paas.ink-plan-details.error.description',
                'Please refresh the page.'
              )
            ]}
          />
        }
      >
        <InkPlanDetails
          t={t}
          pages={inkPlan?.pages}
          rollover={inkPlan?.rolloverPages}
          additional={inkPlan?.additionalPages}
          additionalPrice={inkPlan?.additionalPagesPrice}
          isSubscriptionHavePaper={isSubscriptionHavePaper}
          paperEligibility={addRemovePaper}
          handleaddpaperService={handleaddpaperService}
          handleremovePaperService={handleremovepaperService}
        />
      </LoadingHandler>
    </PrintPlanStaticHeightCard>
  )
}

export default InkPlanDetailsCard

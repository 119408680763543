import {
  AnalyticsEvents,
  CdmEvent
} from '@monetization/hpaip-ui-shared-components'

type CustomAnalyticsEvent = Partial<CdmEvent>

interface CartridgeStatusAlertEvent extends CustomAnalyticsEvent {
  screenMode: 'LowOnInk' | 'CartridgeError'
  controlName: 'LearnMore' | 'ViewShipments'
}

interface CartridgeStatusErrorEvent extends CustomAnalyticsEvent {
  screenMode: 'InstalledIncorrectly' | 'NonHpInstantInk' | 'StatusUnavailable'
  controlName: 'LearnMore' | 'ViewShipments'
}

interface DismissNotificationEvent extends CustomAnalyticsEvent {
  screenName:
    | 'PaymentIssueResolved'
    | 'LoadingDataIssueNotification'
    | 'GoogleOneUpgradeError'
}

interface DisplayedCartridgeStatusErrorEvent extends CustomAnalyticsEvent {
  screenMode: 'InstalledIncorrectly' | 'NonHpInstantInk' | 'StatusUnavailable'
}

interface ClickMonthlySummaryEvent extends CustomAnalyticsEvent {
  controlName: 'CreditPages' | 'PlanPages' | 'RolloverPages' | 'AdditionalPages'
}

interface ClickNotificationSupportLinkEvent extends CustomAnalyticsEvent {
  controlName: 'VisitHpSupportPage' | 'ContactSupport'
  screenName: 'SubscriptionHasBeenCancelled' | 'CancellationErrorNotification'
}

interface ClickCitizenPayEvent extends CustomAnalyticsEvent {
  screenName: 'PaymentIsOverdue' | 'SubscriptionSuspended'
}

interface PrintPlanTooltipEvent extends CustomAnalyticsEvent {
  controlName: 'RollOverPages' | 'AdditionalPages'
}

const events: AnalyticsEvents = {
  OverviewDisplayed: () => ({
    // with associatedDeviceProductNumber & associatedDeviceUuid
    screenName: 'Overview',
    action: 'ScreenDisplayed'
  }),
  ClickManagePayments: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ManagePayments',
    controlDetail: 'CitizensPay'
  }),
  ClickManagePaymentsMethod: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ManagePayments',
    controlDetail: 'PGS'
  }),
  ClickManageAddresses: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ManageAddresses',
    controlDetail: 'ShippingAddress'
  }),
  ClickChangePlan: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ChangePlan',
    controlDetail: 'InkPlan'
  }),
  ClickRecyclingEnvelope: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'RecyclingEnvelope',
    controlDetail: 'CartridgeStatus'
  }),
  DisplayedCartridgeStatusAlert: ({ screenMode }) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CartridgeStatusAlert',
    screenMode,
    action: 'ModuleDisplayed'
  }),
  ClickCartridgeStatusAlert: ({
    screenMode,
    controlName
  }: CartridgeStatusAlertEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CartridgeStatusAlert',
    screenMode,
    action: 'ControlHyperLinkClicked',
    controlName
  }),
  DisplayedCartridgeStatusError: ({
    screenMode
  }: DisplayedCartridgeStatusErrorEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CartridgeStatusError',
    screenMode,
    action: 'ModuleDisplayed'
  }),
  ClickCartridgeStatusError: ({
    screenMode,
    controlName
  }: CartridgeStatusErrorEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CartridgeStatusError',
    screenMode,
    action: 'ControlHyperLinkClicked',
    controlName
  }),
  ClickMonthlySummary: ({ controlName }: ClickMonthlySummaryEvent) => ({
    screenName: 'Overview',
    action: 'ControlButtonClicked',
    controlName,
    controlDetail: 'MonthlySummary'
  }),
  ClickBrowsePlans: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'BrowsePlans',
    controlDetail: 'MonthlySummary'
  }),
  ClickViewPrintHistory: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ViewPrintHistory',
    controlDetail: 'MonthlySummary'
  }),
  DisplayedPaymentIssueResolved: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'PaymentIssueResolved',
    action: 'ModuleDisplayed'
  }),
  DisplayedPaymentIsOverdue: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'PaymentIsOverdue',
    action: 'ModuleDisplayed'
  }),
  ClickLearnMore: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'PaymentIsOverdue',
    action: 'ControlHyperLinkClicked',
    controlName: 'LearnMore'
  }),
  DisplayedSubscriptionSuspended: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'SubscriptionSuspended',
    action: 'ModuleDisplayed'
  }),
  ClickCitizensPay: ({ screenName }: ClickCitizenPayEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName,
    action: 'ControlHyperLinkClicked',
    controlName: 'CitizensPay'
  }),
  ClickContactSupport: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'SubscriptionSuspended',
    action: 'ControlHyperLinkClicked',
    controlName: 'ContactSupport'
  }),
  DisplayedSubscriptionNoLongerActive: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'SubscriptionNoLongerActive',
    action: 'ModuleDisplayed'
  }),
  DisplayedLoadingDataIssueNotification: ({ actionAuxParams }) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'LoadingDataIssueNotification',
    action: 'ModuleDisplayed',
    actionAuxParams
  }),
  DisplayedSubscriptionHasBeenCancelled: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'SubscriptionHasBeenCancelled',
    action: 'ModuleDisplayed'
  }),
  ClickNotificationSupportLink: ({
    screenName,
    screenMode
  }: ClickNotificationSupportLinkEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName,
    screenMode,
    action: 'ControlHyperLinkClicked',
    controlName: 'ContactSupport'
  }),
  ClickDismissNotification: ({ screenName }: DismissNotificationEvent) => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName,
    action: 'ControlButtonClicked',
    controlName: 'Dismiss'
  }),
  ClickViewAllSubscriptions: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'ViewAllSubscriptions'
  }),
  ClickCancelSubscription: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'CancelSubscription'
  }),
  ClickEditPrinterName: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'EditPrinterName'
  }),
  ClickSupport: () => ({
    screenName: 'Overview',
    action: 'ControlHyperLinkClicked',
    controlName: 'Support',
    controlDetail: 'HpOneSupport'
  }),
  DisplayedCancelationError: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CancellationErrorNotification',
    action: 'ModuleDisplayed'
  }),
  ClickDismissCancelationError: () => ({
    screenPath: '/ReactPaasDashboardOverview/Overview/',
    screenName: 'CancellationErrorNotification',
    action: 'ControlButtonClicked',
    controlName: 'Dismiss'
  }),
  CreditAvailableTooltip: () => ({
    screenName: 'Overview',
    action: 'ControlButtonClicked',
    controlName: 'CreditAvailable',
    controlDetail: 'CitizensBalance'
  }),
  PrintPlanTooltip: ({ controlName }: PrintPlanTooltipEvent) => ({
    screenName: 'Overview',
    action: 'ControlButtonClicked',
    controlName,
    controlDetail: 'PrintPlan'
  })
}

export default events

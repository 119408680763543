import React from 'react'
import 'finally-polyfill'
import { LDProvider } from '@monetization/hpaip-notification-rules-react'
import Dashboard from './components/Root'
import getEnvironmentVariables from './helpers/getEnvironmentVariables'
import { getStack } from './helpers/getStack'

const Root = (props) => {
  const envVariables = getEnvironmentVariables(getStack(props?.stack))
  const clientSideID = envVariables.variables.LAUNCHDARKLY_CLIENTSIDE_ID

  return (
    <LDProvider
      clientSideID={clientSideID || '624769cf7b765b1471f2d115'}
      user={{ key: 'test@email.com' }}
    >
      <Dashboard {...props} />
    </LDProvider>
  )
}

export { Root }

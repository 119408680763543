import IconInkDoubleDrops from '@veneer/core/dist/esm/scripts/icons/icon_ink_double_drops'
import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import { CUSTOM_COLORS } from '../../__mocks__/constants/styles.constants'
const { highContrastGray } = tokens.color

export const CardLinkText = styled.span`
  color: ${CUSTOM_COLORS.colorDeepBlue};
`

export const StyledIconInkDoubleDrops = styled(IconInkDoubleDrops)`
  && {
    color: ${highContrastGray};
  }
`

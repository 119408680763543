import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { gray9 } = tokens.color
const { family0 } = tokens.typography

export const StyledIconCalendarDay = styled(IconCalendarDay)`
  && {
    color: ${gray9};
  }
`
export const CardErrorContent = styled.p`
  font-family: ${family0};
  color: ${gray9};
`

import React from 'react'
// import { useTranslator } from '../../hooks'
import {
  ErrorSectionContainer,
  ErrorSectionTitle,
  ErrorSectionDescription,
  ErrorSectionButton
} from './Styles'

interface ErrorSectionProps {
  title?: string
  description?: string
  buttonText?: string
  t?: any
}

const ErrorSection = ({
  t,
  title,
  description,
  buttonText
}: ErrorSectionProps) => {
  const handleReloadPage = () => {
    window.location.reload()
  }

  return (
    <ErrorSectionContainer>
      <ErrorSectionTitle>
        {title || t('error-section.title', 'Something went wrong')}
      </ErrorSectionTitle>
      {description && (
        <ErrorSectionDescription>{description}</ErrorSectionDescription>
      )}
      <ErrorSectionButton appearance="secondary" onClick={handleReloadPage}>
        {buttonText || t('error-section.button', 'Try again')}
      </ErrorSectionButton>
    </ErrorSectionContainer>
  )
}

export { ErrorSection }

import tokens from '@veneer/tokens'
import styled from 'styled-components'
const { size2, size4, size1, size5, smMax } = tokens.layout
const {
  size1: fontSize1,
  size3: fontSize3,
  lineHeight2,
  lineHeight3
} = tokens.typography
const { gray9, gray12 } = tokens.color
export const PrinterImage = styled.div`
  width: 158px;
  height: 58px;
`
export const FedexDeviceDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: ${size5};

  @media screen and (max-width: ${smMax}) {
    display: block;
    width: auto;
  }
`
export const DeviceSerialNumber = styled.div`
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${gray9};
`

export const Title = styled.div`
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  color: ${gray12};
  margin-bottom: ${size2};
  @media screen and (max-width: ${smMax}) {
    margin-top: ${size4};
    margin-bottom: ${size1};
  }
`
interface IDeviceImageProps {
  deviceImage?: string
  modelName?: string
  serialNumber?: string
  type?: string
}
export const DeviceImage = styled.img<IDeviceImageProps>`
  width: 130px;
`

import { ProgressBar } from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import { CUSTOM_COLORS } from '../../../__mocks__/constants/styles.constants'

import { CommonProps } from 'src/utils/common-util'

interface PlanProgressProps {
  printPlanPageAllowancePrinted: number
  printPlanPageAllowance: number
  commonProps: CommonProps
}

export const PlanProgress = ({
  printPlanPageAllowancePrinted,
  printPlanPageAllowance,
  commonProps
}: PlanProgressProps) => {
  const { t } = commonProps

  const progressNameColor = CUSTOM_COLORS.colorDeepBlue
  return (
    <ProgressBar
      t={t}
      progressNameColor={progressNameColor}
      color={
        printPlanPageAllowancePrinted <= 0
          ? 'gray1'
          : CUSTOM_COLORS.colorDeepBlue
      }
      total={printPlanPageAllowance}
      current={printPlanPageAllowancePrinted}
      label={t('usage-data-progress.plan.label', 'Plan pages')}
      tip={t(
        'usage-data-progress.plan.tip',
        'The number of pages included in your monthly plan.'
      )}
    />
  )
}

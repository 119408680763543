import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React from 'react'
import { CardErrorContent } from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'

const PaymentNotAvailableCard: React.FunctionComponent<any> = ({ t }) => {
  const getEstimatedChargesTexts = () => {
    return [
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.estimated-charges.inactive',
            'Your monthly charges is no longer available'
          )}
        </CardErrorContent>
      </>
    ]
  }
  return (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<IconCalendarDay size={32} color={'gray9'} />}
            texts={getEstimatedChargesTexts()}
          />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}
export default PaymentNotAvailableCard

import React from 'react'
import {
  CardErrorTitle,
  CardHeader,
  ErrorDesc,
  EstimatedErrorCard
} from '../EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'
import { IconCalendarWarning } from '@veneer/core/dist/esm/scripts/icons'

import moment from 'moment'

const ReturnCard: React.FunctionComponent<any> = ({
  returnDetails,
  t,
  costValue
}) => {
  return (
    <EstimatedChargesHeightCard>
      <EstimatedErrorCard>
        <CardHeader>
          <IconCalendarWarning color={'gray9'} size={32} />
          <CardErrorTitle>
            {t(
              'estimated-charges.error.title.cancellingNew',
              'Return the printer'
            )}
          </CardErrorTitle>
        </CardHeader>
        <ErrorDesc>
          {t('dashboard.paas.estimated-charges.error.cancellingDesc', {
            subEndDate: moment(returnDetails?.timeStamp)
              .add(10, 'days')
              .format('MMM DD, YYYY'),
            cancellationFee: costValue,
            defaultValue:
              'Submit the printer to FedEx by {{subEndDate}} to avoid cancellation fee charges of {{cancellationFee}} plus applicable taxes.'
          })}
        </ErrorDesc>
      </EstimatedErrorCard>
    </EstimatedChargesHeightCard>
  )
}
export default ReturnCard
